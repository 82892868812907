body {
    font-family: 'Futura PT' !important;
}


.connection-banner {
    background-color: #954437;
    height: 65px;
    width: 100%;
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 20px;
    font-family: 'Futura PT';
    position: fixed;
    z-index: 9;
}

.two-line-ellipsis {
    /* display: -webkit-box; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.one-line-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* --------------- Denial Worksheet CSS start -------------- */

.font16 {
    font-size: 16px;
}

.font18 {
    font-size: 18px;
}

.botDivider {
    border-bottom: 1px solid #DDDDDD;
    margin: 4px;
}

.claimTabText {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 4% !important;
    border-radius: 50%;
    top: calc(50% - 25px / 2);
    background: #5CB02C;
    font-family: 'Futura PT';
    font-size: 20px;
    line-height: 28px;
    color: #f9f9f8;
}

.denialTabText {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 4% !important;
    border-radius: 50%;
    top: calc(50% - 25px / 2);
    background: #5CB02C;
    font-family: 'Futura PT';
    font-size: 20px;
    line-height: 25px;
    color: #f9f9f8;
    display: flex;
    align-items: center;
    justify-content: center;
}

.noteDiv {
    display: grid;
    /* padding-left: 30px; */
}

.denialNote {
    width: 90%;
    background: #fff;
    border-radius: 9px;
    height: 70px;
    border: 1px solid #9a9a9a;
    font-size: 18px;
    /* margin-left: 90px; */
}


.denialNote:focus {
    outline: none;
}

.claimDialog {
    /* height: 400px; */
    width: 100%;
    font-family: 'Futura PT';
}

.claimNoteDiv {
    margin-left: 10px;
    margin-top: 10px;
}

.claimNote {
    font-family: 'Futura PT';
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #0E0E2C;
}

/* .noteBot {
    border-bottom: 1px solid #7BBC9B;
    margin: 0px 10px 0px 20px;
    width: 560px;
} */

.claimDialog .noteCard {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 10px;
    /* width: 550px; */
    height: 500px;
    background: #FFFFFF !important;
    border: 1px solid #D8D8D8 !important;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05) !important;
    border-radius: 15px !important;
}




.denialSelect {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 3px 15px 7px 10px;
    gap: 5px;
    width: 100%;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
    background: url('../../assets/icons/drop.svg') no-repeat right;
    background-position: right 15px top 53%;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.02em;
    color: #535353;
    text-align: left;
}

.denialSelect:focus {
    outline: none !important;
}

.denialSelect2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 3px 15px 7px 10px;
    gap: 5px;
    width: 100px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
    background: url('../../assets/icons/drop.svg') no-repeat right;
    background-position: right 15px top 53%;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.02em;
    color: #535353;
    text-align: left;
}

.denialSelect2:focus {
    outline: none !important;
}

.resetBtn {
    color: white !important;
    font-family: 'Futura PT';
    padding: 3px 0px;
    gap: 10px;
    width: 80px;
    height: 30px;
    background: #3681A9 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 9px !important;
}

.saveButn {
    color: white !important;
    font-family: 'Futura PT';
    padding: 3px 0px;
    gap: 10px;
    width: 80px;
    height: 30px;
    background: #00708C !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 9px !important;
}






.insurances-table .denial-table tbody {
    padding: 10px 0px 0px;
}







.foot-right3 {
    width: 100%;
    max-width: 420px;
    text-align: left;
    margin-left: 1400px;
}



/*  --------------- Denial Worksheet CSS end --------------  */

.mr-50 {
    margin-right: 50px;
}

.noRecord {
    font-size: 16px;
    color: #954437;
}

table th {
    white-space: unset !important;
}

.font-face-futura-thead {
    font-family: "Futura PT";
    font-weight: 450;
    font-size: 15px;
}

.font-face-futura {
    font-family: "Futura PT";
    font-weight: 450;
}

.font-face-futura-medium {
    font-family: "Futura Medium PT";
}

.font-face-futura-book {
    font-family: "Futura Book PT";
}

.font-face-underground {
    font-family: 'P22UndergroundBook';
    font-weight: 400;
}

.font-face-roboto {
    font-family: 'Roboto';
    font-weight: 400;
}

.rule-box1 {
    max-height: 250px;
    overflow-y: auto;
    max-width: 260px;
    overflow-x: hidden;
    word-break: break-word;
}

.ruleBtn {
    width: 165px;
    height: 40px;
    background-color: #24476A !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 9px !important;
}

.runSelected {
    background-color: #24476a !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    float: left;
    border-radius: 9px !important;
    width: 120px;
    height: 35px;
}

.rulesRan {
    background-color: #5CB02C !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    float: left;
    border-radius: 9px !important;
    width: 100px;
    height: 30px;
}

.autoRun {
    width: 20px;
    height: 20px;
    border-radius: 20px !important;
}

.table-respo {
    position: relative;
}

.fixtablehead {
    margin: 1rem;
}

.foot-flex {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    align-items: center;
    background-color: #F2F2F2;
    border-radius: 9px;
}

.foot-right {
    width: 100%;
    max-width: 420px;
    text-align: left;
}

.custom-size {
    padding: 0 !important;
    border-radius: 9px;
    background: #fcfcfc;
    border: 1px solid #efefef;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.25);
    /* box-shadow: rgb(0 0 0 / 16%) 0px 10px 36px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 1px; */
}

.innerdata div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.custom-table {
    width: 100%;
    position: relative;
    border-collapse: collapse;
    margin-bottom: 4rem;
    margin-top: 1rem;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px; */
}

.custom-table thead {
    background: #24476A;
    border-radius: 10px;
    height: 70px;
}

.custom-table thead th {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    position: sticky;
    top: 0;
    background: #24476a;
    z-index: 2;
}

.custom-table tr {
    width: 98%;
    margin: 1rem;
}

/* .custom-table tbody tr{
    border-top: 10px solid #fff;
    border-bottom: 10px solid #fff;
} */
.custom-table tr td {
    font-size: 12px;
    padding: 0px 0px 10px 0px;
}

.custom-table th:nth-child(1) {
    border-radius: 9px 0px 0px 9px !important;
    padding: 0px 40px;
}

.custom-table th:last-child span {
    margin-right: 3.5rem;
}

/* .rule-table {
    margin-bottom: 3rem;
} */

.rule-footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color: #fff;
    color: white;
    text-align: center;
    border-radius: 9px;

}

.rule-footer p {
    margin-bottom: 0px;
    font-family: 'Futura PT';
}

.pendingtable-pagination>div {
    min-height: auto;
    align-items: center;

}

.pendingtable-pagination .MuiTablePagination-select,
.pendingtable-pagination .MuiTablePagination-actions button {
    padding-block: 0px !important;
}


.pendingtable-pagination .css-194a1fa-MuiSelect-select-MuiInputBase-input.MuiSelect-select {
    min-height: auto;
    line-height: 25px;
}


.rule-table thead {
    background: #24476A;
    border-radius: 9px;
    height: 70px;
    color: #fff;
    position: relative;
}

.rule-table tbody {
    display: block;
    max-height: 56vh;
    height: 56vh;
    overflow-y: scroll;
    margin-bottom: 4rem;
    padding-right: 15px;
}

.rule-table thead,
.rule-table tbody tr {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.opentoggle2 {
    width: calc(100% - 0px);
    /* width: calc(100% - 440px); */
}

.table-bg td p {
    margin-bottom: 0rem !important;
}

.round {
    position: relative;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
    background-color: #5CB02C;
    border-color: #5CB02C;
}

.round input[type="checkbox"]:checked+label:after {
    opacity: 1;
}

/* .table-bg:nth-child(odd){
      background: #F2F2F2;
      border-radius: 9px;
      box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
  }
  .table-bg:nth-child(even){
    background: #CEF8B6;
    border-radius: 9px;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
} */

.innerdata {
    padding: 18px;
    height: 58px;
}

.table-row td {
    border-bottom: 0px !important;
    border-top: 0px !important;
    white-space: nowrap;
    color: #535353;
    font-size: 12px;
    font-family: 'Futura PT';
    font-weight: 600;
    /* padding: 6px 0px 6px 0px; */
}

.table-row:nth-child(even) .innerdata {
    background-color: #CEF8B6;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 15px;
}

.table-row:nth-child(odd) .innerdata {
    background-color: #F2F2F2;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 15px;
}

.dblock {
    display: block;
}

.table-row:nth-child(even) .innerdata .dblock {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.table-row:nth-child(odd) .innerdata .dblock {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tableresponsivecustom {
    transition: all .3s ease;
    width: calc(100% - 30px);
    position: relative;
}

.tableresponsivecustom .custom-table {
    margin-top: 0;
}

.tableresponsivecustom.opentoggle1 {
    width: calc(100% - 290px);

}

.MuiPaper-elevation1 {
    box-shadow: inset -20px -13px 0px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 0%) !important;
}

.small-label {
    font-size: 13px;
    line-height: 1.2rem;
    text-align: left;
}

.mostR {
    text-align: left;
    line-height: 1.4rem;
    margin: 0.5rem 0rem 0rem 0.5rem;
}

/* insurance dialog content css starts here */

.questionMark {
    margin-top: 25px;
    width: 150px;
    height: 150px;
}

.nevermind {
    padding: 3px 0px;
    width: 257.5px !important;
    height: 50px !important;
    background: #954437 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 9px !important;
}

.submit {
    padding: 3px 0px;
    width: 257.5px !important;
    height: 50px !important;
    background: #3681A9 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 9px !important;
}

/* insurance dialog content css ends here */

/* --------------- charges dialog title ---------------- */

.ruleoutLogo {
    width: 75px;
    height: 100px;
    margin: 45px 0px 0px -37px;
    position: absolute;
}

.dialog-box {
    padding: 100px 0px 0px;
    height: 242px;
}

.dialog-box2 {
    padding: 100px 0px 0px;
    height: 400px;
}

.dialog-box-denail {
    padding: 75px 0px 0px !important;
}

.dialogTitlefontSize {
    font-size: 40px !important;
}

.dialog-box3 {
    padding: 100px 0px 0px;
    height: 310px;
}

.dialog-box4 {
    padding: 100px 0px 0px;
    height: 430px;
}

.dialogTitle {
    font-size: 50px;
    line-height: 75px;
    color: #535353;
    letter-spacing: -0.04em;
}

.dialogText {
    font-size: 24px;
    line-height: 33px;
    letter-spacing: -0.04em;
    color: #535353;
    font-family: 'Futura PT' !important;
}

/* .avatar {
    position: fixed !important;
    width: 200px !important;
    height: 195px !important;
    left: 43.5%;
    top: 170px;
    background: #FFFFFF;
    border-radius: 50% !important;
}
.avatar2 {
    position: fixed !important;
    width: 200px !important;
    height: 195px !important;
    left: 43.5%;
    top: 100px;
    background: #FFFFFF;
    border-radius: 50% !important;
} */
/* .avatar3 {
    position: fixed !important;
    width: 200px !important;
    height: 195px !important;
    left: 44.5%;
    top: 230px;
    background: #FFFFFF;
    border-radius: 50% !important;
} */
.activeBtn {
    padding: 3px 0px !important;
    background: #3681A9 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 9px !important;
    color: #FFFFFF !important;
    height: 50px;
    width: 230px !important;
}

.provBtn {
    padding: 3px 0px !important;
    background: #954437 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 9px !important;
    color: #FFFFFF !important;
    height: 50px;
    width: 230px !important;
}

.dialogBtn {
    padding: 3px 0px !important;
    background: #954437 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 9px !important;
    color: #FFFFFF !important;
    height: 50px;
}

.dialogCancel {
    color: #954437 !important;
    width: 140px !important;
    height: 140px !important;
    margin: 15px;
}

.css-tlc64q-MuiPaper-root-MuiDialog-paper {
    /* border: 1px solid #C6C6C6 !important; */
    /* box-shadow: 3px 5px 9px rgba(0, 0, 0, 0.1) !important; */
    /* border-radius: 9px !important; */
    width: 760px !important;
    background: #FFFFFF !important;
    border: 0px solid #D8D8D8 !important;
    border-radius: 15px !important;
    position: unset !important;
}

.css-rnmm7m-MuiPaper-root-MuiDialog-paper {
    /* border: 1px solid #C6C6C6 !important;
    box-shadow: 3px 5px 9px rgba(0, 0, 0, 0.1) !important;
    border-radius: 9px !important;
    width: 760px !important; */
    width: 760px !important;
    background: #FFFFFF !important;
    border: 1px solid #D8D8D8 !important;
    border-radius: 15px !important;
    position: unset !important;
}


.css-mbdu2s {
    overflow: visible !important;
}

.MuiDialog-container.MuiDialog-scrollPaper.css-hz1bth-MuiDialog-container {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    z-index: 1000;
    position: relative;
    max-height: calc(100% - 64px);
    height: unset !important;
    max-width: 600px;
    width: calc(100% - 64px);

}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    padding: 0px 10px 5px 10px !important;
}

.chargesTitle {
    color: #0E0E2C;
    font-size: 15px;
}

.labelDialog {
    display: flex;
    align-items: center;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.selectDialog {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2px 15px 3px 6px;
    gap: 10px;
    width: 214px;
    height: 28px;
    background: #FFFFFF;
    border: 1px solid #9A9A9A;
    border-radius: 9px;
}

.selectStyles {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url('../../assets/icons/dialog.svg') no-repeat right;
    background-position: right 10px top 50%;
    padding-right: 30px;
    position: relative;
    margin-bottom: 0rem;
}

.closeBtn {
    background-color: #006A96 !important;
    border-radius: 9px !important;
    /* width: 340.5px !important; */
    height: 29px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-start !important;
    padding: 2px 25px !important;
    gap: 10px !important;
    flex: none !important;
    order: 0 !important;
    align-self: stretch !important;
    flex-grow: 0 !important;
}

.refreshBtn {
    background-color: #7BBC9B !important;
    border-radius: 9px !important;
    /* width: 340.5px !important; */
    height: 29px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-start !important;
    padding: 2px 25px !important;
    gap: 10px !important;
    flex: none !important;
    order: 0 !important;
    align-self: stretch !important;
    flex-grow: 0 !important;
}

.date-pick {
    color: #C6C6C6;
    height: 27px;
    /* width: 340.5px; */
    width: 100%;
    border: 1px solid #C6C6C6;
    border-radius: 9px;
    padding: 0px 6px;
    /* css to add calendar icon*/
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url('../../assets/icons/calendar.svg') no-repeat right;
    background-position: right 10px top 50%;
    padding-right: 30px;
    position: relative;
    margin-bottom: 0rem;
}

.react-datepicker__month-container {
    float: left;
    background: #efefef !important;
    top: -18px !important;
}



.first-header {
    width: 165px;
}

.innerdata p {
    margin: 0px !important;
    line-height: 1rem;
}

.rule-table tr td:nth-child(1) {
    width: 75px;
}

.rule-table tr th:nth-child(3) {
    width: 100px;
}

.rule-table tr td:nth-child(3) {
    width: 100px;
}

.rule-table tr th:nth-child(5) {
    width: 130px;
}

.rule-table tr td:nth-child(5) {
    width: 130px;
}

.rule-table tr th:nth-child(6) {
    width: 100px;
}

.rule-table tr td:nth-child(6) {
    width: 100px;
}

.rule-table tr th:nth-child(10) {
    width: 120px;
    padding-right: 42px;
}

.rule-table tr td:nth-child(10) {
    width: 100px;
}

.rule-table tbody::-webkit-scrollbar {
    width: 15px;
}

/* rounded checkbox CSS here */
.round-check {
    position: relative;
    margin-left: 2rem;
}

.round-check label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
}

.round-check label:after {
    border: 3px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 8px;
    left: 6px;
    opacity: 0;
    position: absolute;
    top: 7px;
    transform: rotate(-45deg);
    width: 15px;
}

.round-check input[type="checkbox"] {
    visibility: hidden;
}

.round-check input[type="checkbox"]:checked+label {
    background-color: #5CB02C;
    border-color: #5CB02C;
}

.round-check input[type="checkbox"]:checked+label:after {
    opacity: 1;
}

.foot-left {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dlock {
    font-size: 16px;
    margin-left: 1.5rem;
    line-height: 1.8rem;
    font-weight: 600;
}

.fontw {
    font-weight: 600;
    font-size: 18px;
    color: #0E0E2C;
}

.dateFont {
    color: #9B9B9B;
    font-weight: 300;
    font-size: 12px;
    /* font-size: 0.8rem; */
}

.fontL {
    font-size: 0.8rem !important;
}

.css-cwpu7v {
    overflow-y: unset !important;
    width: unset !important;
    border-radius: 9px !important;
}

.ruleStyle {
    height: 69%;
    text-align: left;
    background-color: #FAFAFA;
    border-radius: 9px;
    padding: 24px 7px;
    margin: 0.6rem;
}

.bottomDivider {
    border-bottom: 1px solid rgb(0, 128, 168);
    margin: 2px 4px 25px 4px;
}

.mrtop-55 {
    margin-top: 60px;
}

/* table border green on checkbox selection */
.tabl-with-border td:nth-child(1) .innerdata {
    border-top: 1px solid #5CB02C;
    border-right: 0px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    border-left: 1px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.tabl-with-border td:nth-child(2) .innerdata {
    border-top: 1px solid #5CB02C;
    border-right: 0px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    border-left: 0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.tabl-with-border td:nth-child(3) .innerdata {
    border-top: 1px solid #5CB02C;
    border-right: 0px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    border-left: 0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.tabl-with-border td:nth-child(4) .innerdata {
    border-top: 1px solid #5CB02C;
    border-right: 0px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    border-left: 0px solid #5CB02C;
    white-space: pre-wrap;
    line-height: 1.2rem;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.tabl-with-border td:nth-child(5) .innerdata {
    border-top: 1px solid #5CB02C;
    border-right: 0px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    border-left: 0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.tabl-with-border td:nth-child(6) .innerdata {
    border-top: 1px solid #5CB02C;
    border-right: 0px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    border-left: 0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.tabl-with-border td:nth-child(7) .innerdata {
    border-top: 1px solid #5CB02C;
    border-right: 0px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    border-left: 0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.tabl-with-border td:nth-child(8) .innerdata {
    border-top: 1px solid #5CB02C;
    border-right: 0px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    border-left: 0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.tabl-with-border td:nth-child(9) .innerdata {
    border-top: 1px solid #5CB02C;
    border-right: 0px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    border-left: 0px solid #5CB02C;
    white-space: pre-wrap;
    line-height: 1.2rem;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.tabl-with-border td:nth-child(10) .innerdata {
    border-top: 1px solid #5CB02C;
    border-right: 1px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    border-left: 0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

/* table border green on mark as reviewed field */
.tbl-with-border td:nth-child(1) .innerdata {
    border-top: 1px solid #5CB02C;
    border-right: 0px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    border-left: 1px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.tbl-with-border td:nth-child(2) .innerdata {
    border-top: 1px solid #5CB02C;
    border-right: 0px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    border-left: 0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.tbl-with-border td:nth-child(3) .innerdata {
    border-top: 1px solid #5CB02C;
    border-right: 0px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    border-left: 0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.tbl-with-border td:nth-child(4) .innerdata {
    border-top: 1px solid #5CB02C;
    border-right: 0px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    border-left: 0px solid #5CB02C;
    white-space: pre-wrap;
    line-height: 1.2rem;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.tbl-with-border td:nth-child(5) .innerdata {
    border-top: 1px solid #5CB02C;
    border-right: 0px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    border-left: 0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.tbl-with-border td:nth-child(6) .innerdata {
    border-top: 1px solid #5CB02C;
    border-right: 0px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    border-left: 0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.tbl-with-border td:nth-child(7) .innerdata {
    border-top: 1px solid #5CB02C;
    border-right: 0px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    border-left: 0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.tbl-with-border td:nth-child(8) .innerdata {
    border-top: 1px solid #5CB02C;
    border-right: 0px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    border-left: 0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.tbl-with-border td:nth-child(9) .innerdata {
    border-top: 1px solid #5CB02C;
    border-right: 0px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    border-left: 0px solid #5CB02C;
    white-space: pre-wrap;
    line-height: 1.2rem;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.tbl-with-border td:nth-child(10) .innerdata {
    border-top: 1px solid #5CB02C;
    border-right: 0px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    border-left: 0px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.tbl-with-border td:nth-child(11) .innerdata {
    border-top: 1px solid #5CB02C;
    border-right: 1px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    border-left: 0px solid #5CB02C;
    border-radius: 0px 9px 9px 0px;
    white-space: pre-wrap;
    line-height: 1.2rem;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.table-row td:nth-child(11) .innerdata {
    border-radius: 0px 9px 9px 0px;
}

.encounter_count {
    color: #535353;
    font-family: 'Futura PT';
}

.round-check {
    position: relative;
    margin-left: 2rem;
}

.round-check label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
}

.round-check label:after {
    border: 3px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 8px;
    left: 6px;
    opacity: 0;
    position: absolute;
    top: 7px;
    transform: rotate(-45deg);
    width: 15px;
}

.round-check input[type="checkbox"] {
    visibility: hidden;
}

.round-check input[type="checkbox"]:checked+label {
    background-color: #5CB02C;
    border-color: #5CB02C;
}

.round-check input[type="checkbox"]:checked+label:after {
    opacity: 1;
}

.foot-left {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dlock {
    font-size: 16px;
    margin-left: 1.5rem;
    line-height: 1.8rem;
    font-weight: 600;
}

.fontw {
    font-weight: 600;
    font-size: 18px;
}

.fontL {
    font-size: 0.8rem !important;
}

.avatar3 {
    position: absolute !important;
    width: 200px !important;
    height: 195px !important;
    left: 33%;
    top: -70px;
    background: #FFFFFF;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar {
    position: absolute !important;
    width: 200px !important;
    height: 195px !important;
    left: 33%;
    top: -70px;
    background: #FFFFFF;
    border-radius: 50% !important;
}

.avatar2 {
    position: absolute !important;
    width: 200px !important;
    height: 195px !important;
    left: 33%;
    top: -70px;
    background: #FFFFFF;
    border-radius: 50% !important;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.widthunset .MuiDialog-container.MuiDialog-scrollPaper.css-hz1bth-MuiDialog-container {
    max-width: unset !important;
}

/* //09/02/2024 */
.m-none {
    margin: unset !important
}

/* 27/03/2024 */

.claims-modal-custom .css-cwpu7v {
    max-width: 759px;
    width: calc(100% - 64px) !important;
}

.denailDrop {
    position: absolute;
    border-radius: 9px;
    width: 147px;
    max-height: 250px;
    overflow-y: auto;
    z-index: 99;
    background: #fff;
}

/* Nandan P starts 27/12/2024 */

.linkedprayer-table table {
    min-width: 1840px;
}

/* .denial-container table tbody::-webkit-scrollbar {
    display: none;
} */

.denial-container table thead th:first-child,
.insurances-table table thead th:first-child,
.claim-table table thead th:first-child {
    width: 50px;
}

.denial-container table tbody td:first-child,
.insurances-table table tbody td:first-child,
.claim-table table tbody td:first-child {
    width: 40px;
    padding-left: 8px;
}

.denial-container table tbody :is(td:nth-child(4), td:nth-child(8)),
.denial-container table thead :is(th:nth-child(4), th:nth-child(8)) {
    width: 170px;
}

.denial-container table thead th:last-child,
.insurances-table table thead th:last-child,
.claim-table table thead th:last-child {
    width: 115px !important;
    text-align: center !important;
    padding-right: 12px;
}

.insurances-table table thead th:last-child {
    padding-right: 0px;
}

.denial-container table tbody td:last-child,
.insurances-table table tbody td:last-child,
.claim-table table tbody td:last-child {
    width: 105px !important;
}

/* .denial-style table  thead :is(th:nth-child(12) , th:nth-child(15)) span {
    white-space: normal;
    text-wrap: wrap;
    width: 30px;
    display: block;
    margin: 0 auto;
   } */

.denial-container table tbody :is(td:nth-child(6), td:nth-child(7), td:nth-child(18)),
.denial-container table thead :is(th:nth-child(6), th:nth-child(7), th:nth-child(18)) {
    width: 140px;
}

.denial-container table tbody :is(td:nth-child(12), td:nth-child(15)),
.denial-container table thead :is(th:nth-child(12), th:nth-child(15)) {
    width: 55px;
}

.insurances-table table tbody :is(td:nth-child(3), td:nth-child(5), td:nth-child(6), td:nth-child(7)),
.insurances-table table thead :is(th:nth-child(3), th:nth-child(5), th:nth-child(6), th:nth-child(7)) {
    width: 100px;
}

.insurances-table table tbody :is(td:nth-child(2), td:nth-child(4)),
.insurances-table table thead :is(th:nth-child(2), th:nth-child(4)) {
    width: auto;
}

.insurances-table.denial-style table .innerdata {
    white-space: normal;
    word-break: break-all;
}

.denial-forminput {
    display: flex;
    align-items: center;
    gap: 8px;
}

.denial-forminput :is(input, select) {
    width: 100%;
    font-size: 12px;
}

.denial-buttons {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.denial-buttons :is(.resetBtn, .saveButn) {
    width: 100%;
    max-width: 90px;
}

.denial-buttons .saveButn {
    border-radius: 9px 0px 0px 9px !important;
    width: fit-content;
}

.denial-buttons .savebtn-dropdown,
.denial-buttons .savebtn-dropdown:hover {
    height: 30px;
    color: #FFFFFF;
    background-color: #00708C;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 9px 9px 0px;
}

.assigned-form {
    padding-right: 35px;
}

.assigned-form .row>* {
    padding: 0px 6px !important;
}

.noteDiv textarea {
    border-color: #D8D8D8;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.05);
    font-size: 14px;
    resize: none;
}

/* @media (max-width:1920px) {
    .avatar {
        position: fixed !important;
        width: 200px !important;
        height: 195px !important;
        left: 44.75%;
        top: 260px;
        background: #FFFFFF;
        border-radius: 50% !important;
    }
    .avatar2 {
        position: fixed !important;
        width: 200px !important;
        height: 195px !important;
        left: 44.75%;
        top: 185px;
        background: #FFFFFF;
        border-radius: 50% !important;
    }
} */


/* 08 Jan */
.claimModal .claimDialog .claimNoteDiv {
    border-bottom: 1px solid #7BBC9B;
    margin: 10px 20px 0px 24px;
    /* padding: 10px; */
}

.claimModal .MuiDialog-container.MuiDialog-scrollPaper.css-hz1bth-MuiDialog-container {
    width: 100% !important;
    max-width: 1024px !important;
}

.claimModal .css-tlc64q-MuiPaper-root-MuiDialog-paper {
    width: 100% !important;
    max-width: 100% !important;
}

.claimModal .claimDialog .css-46bh2p-MuiCardContent-root {
    width: 100%;
    border-bottom: 1px solid #D8D8D8;
}

.claimModal .claimDialog .css-46bh2p-MuiCardContent-root label {
    margin-bottom: 4px;
}

.claimModal .claimDialog .css-46bh2p-MuiCardContent-root label strong {
    font-size: 15px;
    color: #0e0e2c;
}

.claimModal .claimDialog .css-46bh2p-MuiCardContent-root label span {
    font-size: 15px;
    color: #535353;
}

.claimModal .modalHeading {
    width: 100%;
    padding: 10px 16px 0;
    font-size: 18px;
    font-weight: 700;
}

.claimModal .claimDialog .contentBox {
    width: 100%;
    max-width: calc(100% - 12%);
    border-bottom: 1px solid #D8D8D8;
}

.claimModal .claimDialog .innerContent {
    padding: 15px;
    font-size: 16px;
}

.claimModal .close_button {
    width: 100%;
    color: white;
    background: #3681a9;
    padding: 8px;
    border-radius: 10px;
    border: transparent;
    box-shadow: 1px 4px 6px #00000033;
    font-size: 16px;
    font-weight: 500;
}

.claimModal .claimDialog button.navigateBtn {
    background: transparent;
    border: none;
    padding: 10px;
}

.claimModal .claimDialog button.navigateBtn img {
    width: 7px;
}

/* 08 Jan */
.denialFilter-tabel table th {
    padding: 10px 0;
}

.denialFilter-tabel tbody {
    height: calc(100vh - 270px);
}

.section-layout {
    padding: 0px 10px;
    margin-top: 85px;
}

.ruleout-grid,
.ruleout-grid-three,
.ruleout-grid-four {
    display: grid;
    grid-template-columns: minmax(300px, 2fr) 3fr;
    column-gap: 20px;
}

.ruleout-grid-three {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 12px;
    align-items: center;
}

.ruleout-grid-four {
    grid-template-columns: repeat(3, minmax(160px, 1fr)) 1fr;
    column-gap: 8px;
    align-items: center;
}

/* Nandan P */

.autocomplete-select2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 3px 15px 7px 10px;
    grid-gap: 5px;
    gap: 5px;
    width: 100px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
    background: url('../../assets/icons/drop.svg') no-repeat right;
    background-position: right 15px top 53%;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.02em;
    color: #535353;
    text-align: left;
    width: 100%;
}

.denial-forminput :is(input, select)  {
    font-family: 'Futura PT'!important;
}

.autocompleteselect2-container input::placeholder {
    color: #535353!important;
    opacity: 1;
    letter-spacing: -0.02em;
    font-weight: 400;  
    font-size: 12px;
    text-transform: capitalize;
} 


.MuiAutocomplete-popper {
    border: 1px solid #e3e3e3;
}

.MuiAutocomplete-popper  * {
    font-size: 12px!important;
    font-weight: 400;  
   font-family: 'Futura PT';
    letter-spacing: -0.02em;
}

.autocomplete-select2 .MuiBox-root {
    width: 100%;
}

.autocomplete-select2 .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    display: none;
}

.autocomplete-select2 .autocompleteinput-container input {
    padding: 0px !important;
    padding-top: 5px !important;
}

.autocomplete-select2 .css-ptiqhd-MuiSvgIcon-root {
    margin-top: 0px !important;
}

.autocomplete-select2 .autocompleteinput-container>div {
    padding: 0px;
    padding-right: 0px !important;
}

.pending-table table thead th:first-child {
    width: 100px;
}

.pending-table table tbody td:first-child {
    width: 90px;
    padding-right: 10px;
}

.pending-table table {
    width: 100%;
    min-width: 1820px;
}

.pending-table tbody {
    height: calc(100vh - 220px) !important;
}

.pending-table table tbody :is(td:nth-child(5)),
.pending-table table thead :is(th:nth-child(5)) {
    width: 150px;
}

.pending-table table tbody :is(td:nth-child(11), td:nth-child(14)),
.pending-table table thead :is(th:nth-child(11), th:nth-child(14)) {
    width: 55px;
}

.pending-table table thead th:last-child {
    width: 115px;
}

.pending-table table tbody td:last-child {
    width: 105px;
    padding-right: 10px;

}

.pending-table table tbody :is(td:nth-child(3), td:nth-child(6), td:nth-child(7)), .pending-table table thead :is(th:nth-child(3), th:nth-child(6), th:nth-child(7)) {
    width: 150px;
}
.pending-table table tbody :is(td:nth-child(18) , td:nth-child(17) , td:nth-child(16)), .pending-table table thead :is(th:nth-child(18), th:nth-child(17), th:nth-child(16)) {
    width: 120px;
}

/* .pending-table table tbody td {
    padding-right: 10px;
} */

.pending-table .innerdata {
    font-size: 12px !important;
}

.table-row:nth-child(odd) td:last-child .innerdata {
    padding-inline: 0px;
}

.pendingtable-pagination .MuiTablePagination-actions svg {
    visibility: visible !important;
}

/* Nandan P starts 07/20/2025 */

.ruleout-grid .autocomplete-select2,
.ruleout-grid .denialSelect2 {
    background-color: #FFFFFF;
}

.duedate {
    background-color: red;
    color: #FFFFFF;
    border-radius: 25px;
    width: 100%;
    padding: 4px 8px;
    max-width: 80px;
}

.datanotfound {
    height: calc(100vh - 351px);
}

.logout-modal .MuiPaper-elevation1 {
    margin-left: 16px;
    margin-top: 4px;
}

.logout-modal .css-6hp17o-MuiList-root-MuiMenu-list {
    padding-bottom: 0px !important;
}

.denial-style table .table-row .innerdata {
    height:44px;    
}

@media screen and (max-width:1800px) {
    .rule-table tbody {
        max-height: 52vh;
    }

    .ruleStyle {
        height: 66%;
        padding: 6px 7px;
    }

    .mrtop-55 {
        margin-top: 45px;
    }

    .rule-box1 {
        max-height: 220px;
        max-width: 260px;
    }

    .table-row:nth-child(odd) .innerdata {
        white-space: pre-wrap;
    }

    .table-row:nth-child(even) .innerdata {
        white-space: pre-wrap;
    }

    /* .avatar {
        position: fixed !important;
        width: 200px !important;
        height: 195px !important;
        left: 44%;
        top: 220px;
        background: #FFFFFF;
        border-radius: 50% !important;
       
    }
    .avatar2 {
        position: fixed !important;
        width: 200px !important;
        height: 195px !important;
        left: 44.25%;
        top: 150px;
        background: #FFFFFF;
        border-radius: 50% !important;
    } */
    /* .avatar3 {
        position: fixed !important;
        width: 200px !important;
        height: 195px !important;
        left: 44%;
        top: 190px;
        background: #FFFFFF;
        border-radius: 50% !important;
    } */

}

@media (min-width:1600px) {
    .first-header {
        width: 75px;
    }

    .custom-table th {
        padding-left: 20px !important;
    }
}

@media (max-width:1536px) {
    .rule-table tbody {
        max-height: 46vh;
    }

    .first-header {
        width: 5%;
    }

    .mrtop-55 {
        /* margin-top: -10px !important; */
        /* margin-top: 20px !important; */
        margin-top: -13px !important;
    }

    .ruleBtn {
        height: 30px;
    }

    .ruleStyle {
        height: 62%;
        padding: 6px 7px;
    }

    .rule-box1 {
        max-height: 200px;
        max-width: 260px;
    }

    /* .avatar {
        position: fixed !important;
        width: 200px !important;
        height: 195px !important;
        left: 43.5%;
        top: 160px;
        background: #FFFFFF;
        border-radius: 50% !important;
    }
    .avatar2 {
        position: fixed !important;
        width: 200px !important;
        height: 195px !important;
        left: 43.5%;
        top: 95px;
        background: #FFFFFF;
        border-radius: 50% !important;
    } */
    /* .avatar3 {
        position: fixed !important;
        width: 200px !important;
        height: 195px !important;
        left: 43.5%;
        top: 140px;
        background: #FFFFFF;
        border-radius: 50% !important;
    } */
}

@media (max-width:1500px) {
    .first-header {
        width: 5%;
    }

    .custom-table th {
        padding-left: 30px !important;
        color: #fff;
    }
}

.cstm-tooltip-container {
    overflow: visible;
}

.custom-toltip{
    position: relative;
    overflow: visible !important;
    text-overflow: unset !important;
}

.innerdata.cstm-tooltip-container div {
    overflow: visible;
    white-space: break-spaces !important;
    text-overflow: unset !important;
}

.tooltip-text{
    display: none;
    position: absolute;
    background: rgb(228, 228, 228);
    right: -5px;
    top: 5px;
    max-width: 500px;
    width: 500px;
    /* height: 240px; */
    height: auto;
    max-height: 240px;
    text-wrap-mode: wrap;
    padding: 20px;
    border: 1px solid #b0adad;
    border-radius: 5px;
    font-size: 14px;
    text-align: start;
    overflow-y: auto !important;
    transform: translate(0, 15px);
    z-index: 2;
}

.custom-toltip:hover .tooltip-text{
    display: block;
}

.table-row .innerdata.cstm-tooltip-container{
    overflow: visible;

}

.innerdata.cstm-tooltip-container .tooltip-text p{
    margin-bottom: 10px !important;
}