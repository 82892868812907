.ml-30 {
    margin-left: 25px;
    display: flex;
    align-items: center;
}

.d-flex {
    display: flex;
    justify-content: end;
}

.pos-ab {
    position: absolute !important;
    bottom: 64px;
}

.pos-logo {
    position: absolute !important;
    bottom: 12px;
}

.copyDrop1 {
    width: 400px;
    height: 30px;
    background-color: #ffffff;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgb(0 0 0 / 5%);
    border-radius: 9px;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.02em;
    color: #535353;
    font-family: 'Futura PT';
    background-image: url('../../assets/icons/drop.svg');
    background-repeat: no-repeat;
    background-position: right 8px top 53%;
}
.copyDrop1:focus {
    outline: none;
}

.navlinkTxt {
    font-family: 'Futura PT';
    color: #ffffff;
    font-size: 18px;
}
.navDiv {
    color: #D8D8D8;
    line-height: 14px;
    margin-top: 1rem;
}
.botDiv {
    border-bottom: 1px solid #ffffff50;
    margin: 15px 0;
}
#basic-menu .css-6hp17o-MuiList-root-MuiMenu-list {
    background-color: #00708C !important;
    border-radius: 4px;
    border: none !important;
    padding-bottom: 8px !important;
}
.nav {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px !important;
    gap: 20px;
}
.abx {
    width: 100% !important;
    max-width: 50px !important;
    padding: 0px 2px;
}

select {
    background-image: url('../../assets/icons/drop.svg');
    background-repeat: no-repeat;
    background-position: right 8px top 53%;
}

@media  (min-width: 1024px) {
    .copyDrop1 {
        width: 250px;
    }
    .abx {
        width: 100% !important;
        max-width: 50px !important;
        padding: 0px 0px;
    }
  }
  @media  (min-width: 1440px) {
    .copyDrop1 {
        width: 400px;
    }
    .abx {
        width: 100% !important;
        max-width: 50px !important;
        padding: 0px 2px;
    }
  }
  /* 24 Dec */
  .custom-nav{
    margin-left: 20px !important;
  }
  .custom-nav.MuiList-padding .MuiListItem-gutters{
        padding-right: 0 !important;
        padding-left: 0 !important;
        
    }

    .custom-dropdown .custom-list-item{
        max-width: 300px;
        width: 100%;
    }

    .headericon-dropdown .MuiPaper-root {
        max-width: 270px !important;
        width: 100% !important;
        border: none !important;
        padding: 0 !important;
        top: 75px !important;
        border-radius: 5px;
        overflow: hidden !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        background-color: #00708C !important;
    }
    

    .nav-menu-header span{
        color: #ffffff50;
    font-size: 22px;
    font-weight: 600;
    }


    .css-6hp17o-MuiList-root-MuiMenu-list li{
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }
