.dashboardText {
    width: 101px;
    height: 31px;
    font-family: 'Futura PT';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #535353;
}
.workLoadCard , .autorunCard {
    font-family: 'Futura PT';
    font-style: normal;
    font-weight: 450;
    font-size: 20px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 10px;
    background: #FAFAFA !important;
    border: 1px solid #D8D8D8 !important;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05) !important;
    border-radius: 15px !important;
    text-align: left;
}
.workLoadHead {
    padding: 15px 11px 12px 32px !important;
    width: 100%;
    height: 44px !important;
    background: #24476A !important;
    border-radius: 15px 15px 0px 0px !important;
}

.barChartWorkload {
    padding: 10px 10px 10px 30px;
    /* width: 806.5px; */
    /* height: 44px; */
    background: #24476A;
    border-radius: 15px 15px 0px 0px;
    /* margin-top: 14px; */
}

.bar-chart-Content {
    padding: 0px 0px;
    /* width: 804.5px; */
    /* height: 368px; */
    background: #FFFFFF;

}
.autorunCard {
    /* font-family: 'Futura PT';
    font-style: normal;
    font-weight: 450;
    font-size: 20px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 10px;
    background: #FAFAFA !important;
    border: 1px solid #D8D8D8 !important;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05) !important;
    border-radius: 15px !important;
    text-align: left; */
    height: 100%;
}
.manageAutoRun {
    /* width: calc(117% - 155px) !important; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 9px;
    /* margin-left: 9px; */
    position: relative;
    height: 100%;
}
.manageAutoRun .innerdata {
    padding: 18px;
    height: 48px;
}
.tableAutorun {
    height: 400px;
    margin-bottom: 1.8rem;
    overflow-y: auto;
}
.tableAutorun thead {
    width: 90%;
    height: 37px;
    position: sticky;
    top: 0;
    background-color: #24476A;
    border-radius: 9px;
}
.tableAutorun tbody {
    display: block;
    max-height: 35vh;
    height: 28vh;
    margin-bottom: 3rem;
    margin-top: 0.75rem;
    margin-left: 10px;
}
.tableAutorun thead,
.tableAutorun tbody tr {
    font-family: 'Futura PT';
    font-style: normal;
    font-weight: 450;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    display: table;
    table-layout: fixed;
    width: 100%;
}
.noRecord {
    font-size: 16px;
    color: #954437;
}
.tabmanageAutoRun {
    width: 92.5% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 9px;
    margin-left: 95px;
}
.tablemanageAutoRun .innerdata {
    padding: 18px;
    height: 48px;
}
.manageAutoRunTable thead {
    width: 90%;
    height: 50px;
    background-color: #24476A;
    border-radius: 9px;
}
.manageAutoRunTable tbody {
    display: block;
    max-height: 65vh;
    height: 10vh;
    overflow-y: scroll;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
}
.manageAutoRunTable thead,
.manageAutoRunTable tbody tr {
    display: table;
    table-layout: fixed;
    width: 100%;
}
.block_center>div {
    text-align: center;
    width: 100% !important;
    height: 87% !important;
}

.block_center p {
    text-align: center;
    margin-bottom: 0rem;
    margin-top: 1rem;
}
.one-line-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.table-row:nth-child(even) .innerdata{
    background-color: #cef8b6;
}
table th{
    border-bottom: 0px!important;
    white-space: nowrap;
}
.table-row:nth-child(odd) .innerdata{
    background-color: #f2f2f2;
}
.table-row td{
    border-bottom: 0px!important;
    border-top: 0px!important;
    white-space: nowrap;
}

.processCard {
    text-align: left;
    font-family: 'Futura PT';
    font-style: normal;
    font-weight: 450;
    /* font-size: 20px; */
    line-height: 26px;
    letter-spacing: -0.02em;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 10px;
    background: #FAFAFA !important;
    border: 1px solid #D8D8D8 !important;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05) !important;
    border-radius: 15px !important;
}

.processCardHead {
    padding: 14px 28px 12px 99px !important;
    width: 108%;
    height: 44px !important;
    background: #24476A !important;
    border-radius: 15px 15px 0px 0px !important;
}

.practiceVolumeSelect {
    /* margin-left: -573px !important ; */
    font-family: 'Futura PT';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: #0E0E2C;
}

.practiceDropdown {
    padding: 0px 15px 4px 10px;
    gap: 5px;
    width: 650px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
}

.dateRangeSelct {
    padding: 0px 15px 4px 10px;
    gap: 5px;
    width: 350px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
    background-image: url('../../assets/icons/drop.svg');
    background-repeat: no-repeat;
    background-position: right 8px top 53%;
}
.dateRangeSelct:focus {
    outline: none;
}

.processedClaims {
    padding: 10px 10px 10px 30px;
    gap: 10px;
    /* width: 97%; */
    /* margin-left: 25px; */
    height: 40px;
    background: #24476A;
    border-radius: 15px 15px 0px 0px;
}

.chartLine {
    padding: 10px;
    /* margin-left: 26px; */
    gap: 10px;
    /* width: 97%; */
    height:398px;
    background: #FFFFFF;
    border-radius: 0px 0px 15px 15px;
}
.manageAutoRun .tableAutorun tbody {
    display: block;
    height: 352px;
    max-height:fit-content!important;
    overflow-y: scroll;
    margin-bottom: 0rem;
    margin-top: 0.75rem;
    margin-left: 10px;
}
.chartLine div canvas{
    height: 385px !important;
}




@media screen and (min-width:320px) and (max-width:767px) {
    .innerdata {
        display: flex;
    }
}

@media screen and (max-width:1800px) {
    .tableAutorun tbody {
        max-height: 51vh;
    }
.manageAutoRun .tableAutorun tbody {
    max-height: 51vh;
}
    .iconBox {
        width: 550px;
        left: calc(50% - 600px/2 - 490px);
    }

    .trigDiv {
        width: 92%;
    }

    .trigSelect {
        margin: -45px 60px 20px 0px;
    }

    .tablemanageAutoRun {
        width: calc(100% - 137px) !important;
    }

    .rule-footer2 {
        bottom: 0px;
        width: 100%;
    }
    .barChartWorkload {
        width: 740px;
        margin-left: 2rem;
    }
    /* .manageAutoRun {
        width: calc(117% - 140px) !important;
    } */
    .bar-custom {
          height: 320px !important;
    }
}

@media screen and (max-width:1536px) {
    .sld{
        width: 87% !important;
        margin-left: 3rem !important;
    }
    .bar-custom {
        height: 320px !important;
        width: 640px !important;
        margin-left: 5rem !important;
  }
    /* .manageAutoRun {
        width: calc(117% - 125px) !important;
    } */
    .tableAutorun tbody {
        max-height: 46vh;
    }
    .manageAutoRun .tableAutorun tbody {
        max-height: 40vh;
        height: 40vh;   
    
    }
    .iconBox {
        width: 530px;
        left: calc(50% - 600px/2 - 380px);
    }

    .trigDiv {
        width: 91.25%;
    }

    .trigSelect {
        margin: -45px 50px 20px 0px;
    }

    .tablemanageAutoRun {
        width: calc(100% - 132px) !important;
    }

    .rule-footer2 {
        bottom: 0px;
        width: 100%;
    }
    .barChartWorkload {
        width: 656px;
        margin-left: 4.5rem;
    }
    .practiceDropdown {
        width: 500px;
    }
    .dateRangeSelct {
        width: 268px;
    }
}