.font-face-underground {
    font-family: 'P22UndergroundBook';
    font-weight: 400;
}

.font-face-futura {
    font-family: 'Futura PT';
    font-weight: 400;
}

.MuiTableCell-head {
    font-family: 'Futura PT' !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    font-style: normal !important;
}

.MuiTableCell-body {
    font-family: 'Futura PT' !important;
    font-size: 15px !important;
    color: #5B5B5B !important;
    font-style: normal !important;
}

.MuiButton-label {
    font-size: 15px !important;
}

.mrt-35 {
    margin-top: 7rem;
}

.mrt-355 {
    margin-top: 1rem;
}

.left-head-section {
    display: flex;
    align-items: center;
    justify-self: center;
    height: 64vh;
}

.left-head-section h4 {
    font-size: 24px;
    line-height: 31px;
    letter-spacing: -0.02em;
    color: #0E0E2C;
}

.table-respon {
    width: 97% !important;
    margin-left: 73px !important;
}

.view_more {
    width: 175px;
    float: right;
    background: linear-gradient(180deg, #24476A 75.71%, #8EAFC2 310.7%);
    color: white !important;
    border-radius: 20px 20px 0px 0px !important;
    position: absolute !important;
    font-family: 'Futura PT' !important;
    bottom: unset;
    right: 10px;
    z-index: 999;
    top: -38px;
}

.view_more span {
    font-family: 'Futura PT' !important;
    text-transform: none !important;
}

.relat {
    position: relative;
    height: auto;
    margin-left: -60px;
}

.abs {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.subheader {
    /* margin-top: 1rem; */
    margin: 1rem 0rem 0rem 0rem;
    padding: 10px;
    background: #24476a;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: 18px; */
    /* margin-left: 73px; */
    width: 101%;
    /* width: 100%; */
}

.scroll-patient {
    height: 640px;
    overflow-y: scroll;
    margin-left: 50px;
}

.cardHead {
    font-weight: bold;
    text-align: left;
    color: #0E0E2C;
    font-size: 24px;
    letter-spacing: -0.04rem;
}

.cardText {
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: #535353;
    font-weight: 400;
}

.cardText2 {
    font-size: 17px;
    line-height: 0px;
    letter-spacing: -0.02em;
    color: #535353;
    font-weight: 400;
}

.cardStyle {
    background: #FAFAFA !important;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05) !important;
}

.editBtn {
    background-color: #24476A !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    color: #FFFFFF !important;
    border-radius: 9px !important;
    height: 24px;
}

/* ----------------- Processing info css ----------------- */

.phead {
    font-weight: 450;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #0E0E2C;
}

.ptitle {
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: #535353;
}

.spanTitle {
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: #0E0E2C;
}

/* ----------------- Dialog css ------------------ */
.MuiDialog-paper {
    /* height: 80vh !important; */
    border-radius: 15px !important;
}

.planTitle {
    height: 50px;
    width: 100%;
    background: linear-gradient(0deg, #24476A 31.25%, #8EAFC2 299.25%);
    border-radius: 15px 15px 0px 0px;
    color: #FFFFFF;
    font-size: 24px;
}

.planTitle .MuiDialogTitle-root {
    padding: 3px 0px;
}

.planTitle span {
    font-weight: bold;
    font-size: 24px;
}

.planDesc {
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: #535353;
}

.planCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 10px;
    background: #FFFFFF !important;
    border: 1px solid #D8D8D8 !important;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05) !important;
    border-radius: 15px !important;
    text-align: left;
}

.planCardHead {
    padding: 17px 10px 10px 20px !important;
    width: 100%;
    height: 40px !important;
    background: #24476A !important;
    border-radius: 15px 15px 0px 0px !important;
}

.planCardHead h5 {
    font-size: 20px;
}

.planContent {
    width: 100% !important;
    font-family: 'Futura PT' !important;
}

.planContentHeight {
    height: 400px;
}

.tableManager {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 9px;
    margin-left: 0px;
}

.tableManager .innerdata {
    padding: 18px;
    height: 48px;
}

.managerTable thead {
    width: 90%;
    height: 50px;
    background-color: #24476A;
    border-radius: 9px;
}

.managerTable th {
    font-size: 20px;
}

.managerTable tbody {
    display: block;
    max-height: 65vh;
    height: 32vh;
    overflow-y: scroll;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
}

.managerTable :not(:first-child) {
    border-top: 0px;
}

.managerTable thead,
.managerTable tbody tr {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.font15 {
    font-size: 15px;
}

/* rounded checkbox CSS here */
.subheader_review_btn {
    background: rgb(247, 247, 247);
    border-radius: 9px;
    margin-right: 15px;
    width: 180px;
    height: 30px;
    border: none;
}

.subheader-review-btn {
    display: flex;
    justify-content: flex-end;
}

.subheader-review-btn .round-check {
    position: relative;
    margin-left: 0;
}

.subheader-review-btn .round-check .checkbox_label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    left: 6px;
    /* left: 15px; */
    position: absolute;
    width: 20px;
}

.round-check .checkbox_label:after {
    border: 3px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 8px;
    left: 2px;
    opacity: 0;
    position: absolute;
    transform: rotate(-45deg);
    width: 15px;
    top: 3px;
}

.round-check input[type="checkbox"] {
    visibility: hidden;
}

.round-check input[type="checkbox"]:checked+.checkbox_label {
    background-color: #5CB02C;
    border-color: #5CB02C;
}

.round-check input[type="checkbox"]:checked+.checkbox_label:after {
    opacity: 1;
}

.subheader-review-btn .dlock {
    width: 115px;
    height: 20px;
    font-family: 'Futura PT';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    /* display: inline-block;
 align-items: center;
 text-align: center; */
    letter-spacing: -0.02em;
}

/* NoViolation Log css */
table.log-table {
    width: 100%;
    max-height: 80vh;
}

table.log-table thead {
    font-weight: 800;
    border-top: 1px solid green;
}

table.log-table tr {
    padding: 10px;
    text-align: center;
    display: flex;
    border-top: 1px solid #ccc;
}

table.log-table td {
    width: 33%;
}

/* .MuiTablePagination-input{
    display: none !important;
} */

.log_bug {
    display: flex;
}

.log_txt {
    display: flex;
    justify-content: flex-start;
    width: 98%;
}

.bug_img {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.buttons{
    display: flex;
}

.activity_close_log {
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; */
    padding: 3px 0px;
    font-size: 18px;
    /* grid-gap: 10px;
    gap: 10px; */
    width: 485px;
    margin: 1rem;
    height: 40px;
    background: #954437;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 9px;
    /* flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0; */
    color: #fff;
    border: none;
    text-transform: uppercase;
}
.activity_submit_log {
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; */
    padding: 3px 0px;
    font-size: 18px;
    /* grid-gap: 10px;
    gap: 10px; */
    width: 485px;
    margin: 1rem;
    height: 40px;
    background: #3681A9;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 9px;
    /* flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0; */
    color: #fff;
    border: none;
    text-transform: uppercase;
}

.log-popup-main {
    height: 650px !important;
    width: 1100px;
    margin: auto;
}
.issue-popup-main {
    height: 750px !important;
    width: 1100px;
    margin: auto;
}

.title {
    padding: 10px 40px 0px 30px !important;
}

.reportIssue {
    font-size: 18px;
    font-family: 'Futura PT';
    font-weight: bold;
    color: #0E0E2C;
    letter-spacing: -0.02rem;
}

.issueCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 10px;
    width: 100%;
    background: #FFFFFF !important;
    border: 1px solid #D8D8D8 !important;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05) !important;
    border-radius: 15px !important;
}

.issueContent {
    width: 100% !important;
    font-family: 'Futura PT' !important;
    padding: 10px 0px 10px 0px !important;
}

.issue_select {
    -webkit-appearance: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 3px 15px 7px 10px;
    gap: 5px;
    width: 100%;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
    background: url('../../assets/icons/drop.svg') no-repeat right;
    background-position: right 15px top 53%;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.02em;
    color: #535353;
    text-align: left;
}
.issue_select:focus {
    outline: none;
}

.free_text {
    width: 100%;
    background: #fff;
    border-radius: 9px;
    height: 300px;
    border: 1px solid #9a9a9a;
    font-size: 18px;
}
.free_text:focus {
    outline: none;
}

.pos_rel {
    position: relative;
}

.subheader-controls-save {
    width: 100px;
    height: 29px;
    background: #006A96;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
    border: none;
}

.subheader_controls_text {
    font-family: 'Futura PT';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    text-align: center;
    color: #FFFFFF
}

.subheader-controls-close {
    position: absolute;
    width: 100px;
    height: 29px;
    left: 92px;
    top: 40px;
    background: #006A96;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
    border: none;
}

.subheader-review-btn {
    margin-top: 10px;
}

.notran-main-h {
    position: relative;
    height: 38px;
}

.notran-main-h button {
    position: absolute;
    right: 64px;
    top: 5px;
}

.planContent .cardLabel {
    font-weight: 600;
    line-height: 25px;
}

.managerTable tbody td {
    padding: 0.8rem 0rem !important;
}

/* .managerTable thead tr th:nth-child(5){
    width: 180px;
}
.managerTable tbody tr td:nth-child(5){
    width: 180px;
}
.managerTable thead tr th:nth-child(4){
    width: 150px;
}
.managerTable tbody tr td:nth-child(4){
    width: 150px;
}
.managerTable thead tr th:nth-child(3){
    width: 180px;
}
.managerTable tbody tr td:nth-child(3){
    width: 180px;
} */
.managerTable thead tr th:nth-child(4) {
    width: 280px;
}

.managerTable tbody tr td:nth-child(4) {
    width: 280px;
}

.managerTable thead tr th:nth-child(3) {
    width: 180px;
}

.managerTable tbody tr td:nth-child(3) {
    width: 180px;
}

.managerTable thead tr th:nth-child(2) {
    width: 280px;
}

.managerTable tbody tr td:nth-child(2) {
    width: 280px;
}

@media (min-width:1600px) {
    .table-wrapper {
        position: absolute;
        bottom: 0px;
    }
}

@media (min-width: 1920px) {
    .table-wrapper {
        position: relative;
        bottom: 0px;
    }
}

@media screen and (max-width:1800px) {
    .scroll-patient {
        height: 555px;
        overflow-y: scroll;
        margin-left: 50px;
    }

    .view_more {
        right: 10px;
        top: -123px;
    }

    .table-respon {
        width: 96.70% !important;
        margin-left: 73px !important;
    }
}

@media (max-width: 1536px) {
    .table-respon {
        width: 96% !important;
    }

    .scroll-patient {
        height: 450px;
        overflow-y: scroll;
        margin-left: 50px;
    }

    .view_more {
        right: 10px;
        top: -38px;
    }

    .planContentHeight {
        height: 335px;
    }
}

@media screen and (max-width:991px) {
    .left-head-section {
        height: auto;
    }

    .mrl-35 {
        margin-left: 100px !important;
    }

    .table-respon {
        width: 90% !important;
        margin-left: 89px !important;
    }
}

@media only screen and (device-width: 1024px) and (device-height: 768px) and (orientation : landscape) {

    .abs {
        position: absolute;
        bottom: -188px;
    }

    .mrl-35 {
        margin-bottom: 9rem;
    }
}