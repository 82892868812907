.datatable-container {
    padding: 0;
    border-radius: 9px;
    background: #fcfcfc;
    border: 1px solid #efefef;
    box-shadow: 3px 3px 7px rgb(0 0 0 / 25%)
}

.datatable thead {
    background: #24476a;
    border-radius: 9px;
    height: 70px;
    color: #fff;
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
}

.datatable tbody {
    display: block;
    /* max-height: 56vh; */
    max-height: 60vh;
    height: 56vh;
    overflow-y: scroll;
    padding-right: 0px;
    overflow-x: hidden;
}

.datatable thead,
.datatable tbody tr {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.datatable-body td {
    border-bottom: 0;
    border-top: 0;
    white-space: nowrap;
    color: #535353;
    font-size: 12px;
    font-family: "Futura PT";
    font-weight: 600;
    padding: 6px 0;
}

.datatable-body .tdata {
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 15px;
    height: 58px;
}

.datatable-body:nth-child(odd) .tdata {
    background-color: #f2f2f2 !important;
}

.datatable-body:nth-child(even) .tdata {
    background-color: #cef8b6 !important;
}

/* .datatable-body td .activeTdata {
    border-top: 1px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.datatable-body td:first-child .activeTdata {
    border-top: 1px solid #5CB02C;
    border-left: 1px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
}

.datatable-body td:last-child .activeTdata {
    border-top: 1px solid #5CB02C;
    border-right: 1px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    box-shadow: 6px 6px 8px rgba(92, 176, 44, 0.25);
} */

.text-signature {
    color: #5CB02C;
}

.datatable-col {
    width: 100px;
}

.datatable-loader {
    height: 100px;
    width: 100px;
    color: #24476a;
    margin-top: 40px;
}

.datatable-body td:first-child .tdata {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

}
/* .datatable-body td:first-child .activeTdata {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top: 1px solid #5CB02C;
    border-left: 1px solid #5CB02C;
    border-bottom: 1px solid #5CB02C;
    box-shadow: 6px 6px 8px rgb(92 176 44 / 25%);
} */

.datatable-body td:last-child .tdata {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

}
.datatable-body td:last-child .activeTdata {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}