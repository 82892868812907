body {
  font-family: 'Futura PT';
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.font-face-futura {
  font-family: "Futura PT";
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5CB02C;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5CB02C;
}

.disable {
  background: #EFEFEF;
}

.MuiButton-label {
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  padding: 0px 10px;
  font-size: 19px;
}

.buttonEbd {
  margin-top: 0rem;
}

.rule-box {
  max-height: 250px;
  overflow-y: auto;
  max-width: 240px;
  overflow-x: hidden;
  word-break: break-word;
}

.picon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  float: right;
  cursor: pointer;
}

.tleft {
  position: absolute;
  left: 0;
  top: -10px;
}

.selectStyle {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url('../../assets/icons/drop.svg') no-repeat right;
  background-position: right 10px top 50%;
  /* padding-right: 30px; */
  position: relative;
  margin-bottom: 0rem;
  padding: 7px 15px 7px 10px;
  width: 100%;
  height: 55px;
  background-color: #FFFFFF;
  border: 1px solid #D8D8D8;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
  border-radius: 9px;
  font-size: 18px;
}

.selectStyle:focus {
  /* outline: none !important; */
  outline-color: #5CB02C !important;
}

.textbox:focus {
  outline-color: #5CB02C !important;
}

.ui-helper-hidden-accessible {
  display: none;
}

.styles_no-focus__2130q {
  border-radius: 9px;
  height: 25px !important;
  margin: 10px;
  font-size: 0.8rem;
  background-color: transparent !important;
}

.styles_no-focus__2130q::placeholder {
  opacity: 0 !important;
}

.chip-green {
  border-radius: 9px !important;
  padding: 0px 10px 0px 10px;
  height: auto !important;
  /* height: 30px !important; */
  max-height: 30px !important;
  overflow-y: visible !important;
  overflow-x: hidden !important;
}

.styles_show__3kLFl {
  background-color: #5CB02C !important;
  color: white !important;
  font-size: 14px;
  margin: 1px 0px 0px -6px !important;
  padding: 0px 0px 0px 10px !important;
  max-height: 24px !important;
}

.styles_show__3kLFl svg {
  filter: brightness(0) invert(1);
}

.styles_chip-input__3aumB {
  background-color: #FFFFFF !important;
  border: 1px solid #D8D8D8 !important;
  border-radius: 20px !important;
  width: 285px !important;
  height: 28px !important;
}

.styles_chip-input__3aumB .styles_chip__i-PT7 {
  height: auto !important;
}

.chip-green .p-2 {
  padding: 0px 0px 0px 10px !important;
}

.styles_shadow-primary__2LDux {
  box-shadow: 0 0 0 0 var(--react-chip-input__box-shadow-color, var(--primary, white)) !important;
}



/* CSS for input box with chips */

.chips {
  border: 1px solid #9A9A9A;
  border-radius: 9px;
  width: 66.2%;
  max-width: 100%;
  min-height: 50px;
  margin-right: 9.2rem;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  background: #FFFFFF;
  justify-content: start;
}

.chips-element {
  border-radius: 9px;
  background-color: #5CB02C;
  padding: 5px 10px 5px 10px;
  margin: 5px;
  color: white;
  font-size: 18px;
  display: inherit;
}

#chips-input {
  border: none;
  appearance: none;
  border-radius: 9px;
  margin: 3px;
  font-size: 18px;
  width: 10%;
  text-align: left !important;
}

#chips-input:focus {
  outline: none;
}

.chip-del-btn {
  font-size: 18px;
}

.chip-del-btn:hover {
  cursor: pointer;
}

.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  right: 0px !important;
}

/* CSS for autocomplete */
.css-i4bv87-MuiSvgIcon-root {
  display: none
}

.css-ptiqhd-MuiSvgIcon-root {
  margin-top: -10px !important;
}

.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  background: url('../../assets/icons/caret.svg') no-repeat right;
  background-position: right 10px top 10%;
  padding-right: 23px;
  position: absolute;
  top: 18px;

}

.MuiAutocomplete-hasPopupIcon.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding-right: 20px !important;
  border-radius: 10px !important;
  border: 1px solid rgb(154, 154, 154) !important;
  background-color: #FFFFFF !important;
  min-height: 50px !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  /* line-height: 2.5; */
}
 
.cd-flex .css-segi59 {
  padding-right: 20px !important;
  border-radius: 10px !important;
  background-color: #FFFFFF !important;
  min-height: 50px !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  border: 1px solid #9a9a9a !important;
}

.class2 .dropd {
  background-color: #FFFFFF !important;
  z-index: 999;
  /* width: 66% !important;
  margin-right: 9.3rem !important; */
  /* width: 83% !important;
  margin-left: 0.8rem !important; */
  padding: 7px 15px 7px 10px;
  height: 45px !important;
  border: 1px solid #D8D8D8 !important;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05) !important;
  border-radius: 9px !important;
  background: url('../../assets/icons/drop.svg') no-repeat right;
  background-position: right 10px top 50%;
}

.dropd {
  background-color: #FFFFFF !important;
  z-index: 999;
  /* width: 66% !important;
  margin-right: 9.3rem !important; */
  /* width: 77% !important; */
  /* margin-left: 6.8rem !important; */
  padding: 7px 15px 7px 10px;
  height: 45px !important;
  border: 1px solid #D8D8D8 !important;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05) !important;
  border-radius: 9px !important;
  background: url('../../assets/icons/drop.svg') no-repeat right !important;
  background-position: right 10px top 50% !important;
}

.inputbox {
  background-color: #FFFFFF !important;
  text-align: left;
  z-index: 9999;
  /* width: 66% !important;
  margin-right: 9.3rem !important; */
  width: 77% !important;
  margin-left: 6.8rem !important;
  padding: 7px 15px 7px 10px;
  height: 45px !important;
  border: 1px solid #D8D8D8 !important;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05) !important;
  border-radius: 9px !important;
}

.dropd .css-segi59 {
  border: 0px !important;
}

.dropd2 {
  /* width: 81.5% !important; */
  width: 100% !important;
  background-color: #FFFFFF !important;
  z-index: 999;
  /* margin-left: 6.8rem !important; */
  margin-left: 0rem !important;
  text-align: left;
  padding: 7px 15px 7px 10px;
  height: 45px !important;
  border: 1px solid #D8D8D8 !important;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05) !important;
  border-radius: 9px !important;
  background: url('../../assets/icons/drop.svg') no-repeat right !important;
  background-position: right 10px top 50% !important;
}

.inputbox2 {
  width: 81.5% !important;
  margin-left: 7rem !important;
  background-color: #FFFFFF !important;
  z-index: 9999;
  text-align: left;
  padding: 7px 15px 7px 10px;
  height: 45px !important;
  border: 1px solid #D8D8D8 !important;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05) !important;
  border-radius: 9px !important;
}

.days-select {
  /* width: 60.5%; */
  width: 53.5%;
  height: 55px !important;
  margin-left: 0.5rem;
  height: 55px !important;
}

.setgap {
  width: 66.666667%;
}

.gap-right {
  margin-right: 1.5rem !important;
  /* margin-right: 9.5rem !important; */
}

.css-1l6di18 .MuiOutlinedInput-root {
  padding: 0px !important;
}

/* .css-vubbuv {
  font-size: 2.5rem!important;
fill: transparent!important;
transform: scaleY(0);
}  */

.css-1l6di18 .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  /* top: 4px;
right: 0px!important; */
  background: url('../../assets/icons/caret.svg') no-repeat right;
  background-position: right 10px top 10%;
  padding-right: 0px;
  position: absolute;
  top: 15px;
  right: 2px !important;

}

.mat-ripple-element {
  display: none;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator.css-uge3vf:focus {
  /* outline: none; */
  visibility: hidden;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
  visibility: hidden;
}

.cd-flex .css-feqhe6 {
  border-radius: 10px !important;
  background-color: #FFFFFF;
  min-height: 50px !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  min-width: 0px;
  padding: 0px !important;
  margin: 0px;
  border: 0px;
  vertical-align: top;
  /* width: 66% !important; */
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator.css-uge3vf {
  visibility: hidden;
}

.MuiAutocomplete-hasPopupIcon.css-1l6di18 .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-1l6di18 .MuiOutlinedInput-root {
  padding-right: 39px;
  border: 1px solid rgb(154, 154, 154) !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1l6di18 .MuiOutlinedInput-root {
  padding-right: 65px;
  border: 1px solid rgb(154, 154, 154);
  border-radius: 10px;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1l6di18 .MuiOutlinedInput-root {
  padding-right: 35px !important;
}

.css-1l6di18 .MuiOutlinedInput-root {
  padding: 6px 9px !important;
}

/* .css-segi59, .MuiAutocomplete-hasPopupIcon.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {
background-color: transparent!important;
border: 1px solid #9a9a9a00!important;
}  */
.css-1xs8iq0 .MuiAutocomplete-option.Mui-focused {
  color: #5CB02C !important;
  text-align: center !important;
}

.css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"] {
  color: #5CB02C !important;
  background: transparent !important;
  font-family: 'Futura PT';
}

.css-1xs8iq0 .MuiAutocomplete-option {
  padding: 13px !important;
  font-size: 18px;
  border: 1px solid #f1f1f1;
  display: flex;
  overflow: hidden;
  -webkit-box-pack: start;
  -webkit-box-align: center;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  justify-content: center !important;
  width: 100%;
  outline: 0px;
  -webkit-tap-highlight-color: transparent;
  font-family: 'Futura PT';
}

/* .css-16aw
h2u-MuiAutocomplete-root .MuiAutocomplete-tag {
margin: -3px 3px 3px 3px !important;
max-width: calc(100% - 6px) !important;
} */
.text-wrap {
  min-height: 55px !important;
  height: auto !important;
}

.MuiChip-deleteIcon {
  color: white !important;
}

/*New Code Start*/
.css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option {
  min-height: 48px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: center !important;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  padding-top: 13px !important;
  box-sizing: border-box;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 18px;
  border: 1px solid #f1f1f1;
}

.css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused:focus {
  background-color: rgb(25 118 210 / 0%) !important;
}

.css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused:checked {
  color: #5CB02C !important;
}

.css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused {
  color: #5CB02C !important;
  text-align: center !important;
}

.css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused:active {
  color: #5CB02C !important;
}

/* select[multiple]:focus option:checked {
background: red linear-gradient(0deg, red 0%, red 100%);
} */

.MuiChip-root {
  color: #fff !important;
  background-color: #5CB02C !important;
  height: 33px !important;
  margin: 0px 0px 3px 0px;
}

/* .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
font-family: "Roboto","Helvetica","Arial",sans-serif;
font-weight: 400;
font-size: 1rem;
line-height: 1.4375em;
letter-spacing: 0.00938em;
color: rgba(0, 0, 0, 0.87);
box-sizing: border-box;
position: initial!important;
cursor: text;
display: -webkit-inline-box;
display: -webkit-inline-flex;
display: -ms-inline-flexbox;
display: inline-flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
width: 100%;
border-radius: 4px;
padding-right: 14px;
} */

/*New Code  End*/


/* 13/04/22 */

.MuiAutocomplete-root .MuiFormControl-root fieldset {
  border: 0;
}

/* .MuiAutocomplete-listbox .Mui-focused {
color: red !important;
background-color: blue !important;
} */
/* .css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused{
color: #5CB02C !important;
} */
.css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]:focus-visible {
  color: #5CB02C !important;
  background: transparent !important;
  font-family: 'Futura PT';
}

.css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option {
  font-family: 'Futura PT';
}

.css-1xs8iq0 .MuiAutocomplete-option[aria-selected="true"] {
  background-color: rgb(25 118 210 / 0%) !important;
  color: #5cb02c !important;
}

.css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"] {
  color: #5cb02c !important;
  background: #00000000 !important;
  font-family: 'Futura PT';
}

.MuiChip-root {
  padding: 0 !important;
  /* font-size: 12px !important; */
}

.MuiAutocomplete-endAdornment.css-1q60rmi-MuiAutocomplete-endAdornment button.MuiAutocomplete-clearIndicator {
  position: relative;
  right: 6px;
}

.MuiAutocomplete-endAdornment.css-1q60rmi-MuiAutocomplete-endAdornment button.MuiAutocomplete-clearIndicator:hover {
  background: transparent;
}

.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0.5px 4px 0.5px 6px !important;
}

.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 6px 9px !important;
}

/* .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {
padding: 10px 36px !important;
} */
.MuiChip-label {
  font-family: 'Futura PT';
}

/* .css-bckmzb-MuiAutocomplete-popper{
display: none !important;
} */
/* end */

/* .css-wb57ya-MuiFormControl-root-MuiTextField-root{
width: 66%!important;
} */


.main-content {
  /* width: calc(100% - 73px); */
  height: calc(100vh - 142px);
  /* margin-left: 90px; */
  display: flex;
  margin-top: 74px;
  padding: 0px 0px 0px 16px;
  gap: 20px;
}

.content-area {
  width: 100%;
  display: flex
  ;
      flex-direction: column;
      justify-content: center;
  /* margin-right: 1rem;
  margin-top: 1rem; */
}

.content-area .MuiPaper-rounded {
  border-radius: 10px !important;
}

.right-sidebar {
  width: 100%;
  max-width: 275px;
  position: relative;
  /* margin-right: 1rem; */
}

.c-flex {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.card-style {
  background: #FAFAFA;
  border: 1px solid #B3B1B1;
  box-sizing: border-box;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.25);
  border-radius: 9px;
  height: calc(100vh - 170px);
  padding-bottom: 15px;
}

.custom-scroll {
  /* height: 100%; */
  height: 96.75%;
  overflow: scroll;
}

.the {
  text-align: right;
}

.MuiCardContent-root:last-child {
  position: relative;
  padding: 15px;
}

.question-mark {
  position: absolute;
  top: 10px;
  right: 10px;
}

.numeric-digit {
  font-size: 34px;
  color: #5CB02C;
  float: left;
  font-weight: 500 !important;
  left: 15px;
  top: 30px;
  margin-right: 1rem;
}

.rule-select {
  width: 100%;
  max-width: 400px;
  border-radius: 6px;
}

.pos {
  position: relative;
  width: 66%;
}

.cd-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-select {
  width: 77% !important;
  margin-left: 6.5rem !important;
  /* width: 66%;  
  margin-right: 9.3rem; */
  height: 50px;
  font-size: 18px;
  background: #fff;
  border-radius: 9px;
  border: 1px solid #9A9A9A;
}

.rulestyle {
  width: 259px;
  height: 100%;
  text-align: left;
  background: #E4E4E4;
  border-radius: 9px;
  padding: 24px 7px;
  /* overflow: auto; */
}

.reason {
  width: 100%;
  background: #fff;
  border-radius: 9px;
  height: 120px;
  border: 1px solid #9a9a9a;
  font-size: 18px;
}

.header-flex {
  display: flex;
  justify-content: end;
  align-items: center;
}

.search_content {
  margin-right: 1rem;
  width: 100%;
  max-width: 400px;
}

.search_content select {
  border-radius: 6px;
  outline: none;
}

.search_content select:focus-visible {
  outline: none;
}

.ac_admin {
  width: 100%;
  max-width: 220px;
  margin-right: 2rem;
}

.selectStyle {
  padding: 10px;
}

.custom-table p {
  margin-bottom: 0px;
  line-height: 1.2rem;
}

.mrtop-70 {
  margin-top: 70px;
}


.cdflex {
  display: flex;
  align-items: center;
}

.cdflex select {
  width: 30%;
  margin-left: 0.5rem;
}

.mr-r {
  margin-right: 0rem;
  margin-left: 2rem;
}

.mr-r2 {
  margin-right: 8rem;
}

.gapl {
  margin-left: 4rem;
}

.css-1fu2e3p-MuiPaper-root-MuiDialog-paper {
  max-width: 1000px !important;
}

/* .img-avatar {
  position: fixed !important;
  width: 250px !important;
  height: 250px !important;
  left: 43.75%;
  top: 65px;
  background: #FFFFFF;
  border-radius: 50% !important;
} */

.image {
  margin-top: 46px;
  width: 160px;
  height: 160px;
}

.pleaseConfirm {
  color: #535353;
  font-size: 50px;
  font-weight: normal;
  font-family: 'Futura PT';
  letter-spacing: 0rem;
}

.head1 {
  color: #535353;
  font-size: 34px;
  font-weight: 600;
  font-family: 'Futura PT';
  letter-spacing: 0rem;
}

.head2 {
  color: #0E0E2C;
  font-size: 24px;
  font-weight: 600;
  font-family: 'Futura PT';
  letter-spacing: 0rem;
}

.textColor {
  color: #535353;
  font-size: 24px;
  font-weight: normal;
  font-family: 'Futura PT';
  letter-spacing: 0rem;
}

.view {
  font-size: 20px;
  font-family: 'Futura PT';
  color: #5CB02C;
  font-weight: 600;
  cursor: pointer;
}

.actionButton {
  display: flex;
  justify-content: center;
  width: 100%;
}

.never-mind {
  padding: 3px 0px;
  gap: 10px;
  width: 460px !important;
  height: 50px;
  background: #954437 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 9px !important;
}

.looks-good {
  padding: 3px 0px;
  gap: 10px;
  width: 460px !important;
  height: 50px;
  background: #3681A9 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 9px !important;
}

.class3 .col-lg-1 {
  width: 15% !important;
}

.class3 .col-lg-5 {
  flex: 0 0 auto;
  width: 38.666667% !important;
}

.class1 {
  margin-left: 1rem;
}

.class2 {
  /* margin-left: 5rem; */
  /*06/11/2023*/
  margin: 0px 30px;
}

.class2 .col-lg-1 {
  flex: 0 0 auto;
  width: 8.633333%;
}

.class1 .col-lg-1 {
  flex: 0 0 auto;
  width: 11.333333%;
}

/* 02/21/2024 */

.rolesalert {
  background-color: #ffffff !important;
  width: 100% !important;
  max-width: 760px;
  height: fit-content !important;
}

.boxWidth :is(.css-1fu2e3p-MuiPaper-root-MuiDialog-paper , .css-18i3v7t ) {
  overflow: visible !important;
  margin: 0px;
  padding: 20px;
}

.boxWidth .css-ypiqx9-MuiDialogContent-root {
  padding-bottom: 0px;
}

.boxWidth .MuiDialog-container.MuiDialog-scrollPaper.css-hz1bth-MuiDialog-container {
  max-width: 1200px;
  position: relative;

}

.boxWidth .img-avatar {
  position: absolute !important;
  top: 0 !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 250px !important;
  height: 250px !important;
  background: #FFFFFF !important;
  border-radius: 50% !important;
}



.claims-modal .MuiDialog-container.MuiDialog-scrollPaper.css-hz1bth-MuiDialog-container {
  max-width: 760px !important;
}

.claims-modal .css-rnmm7m-MuiPaper-root-MuiDialog-paper {
  overflow-y: unset !important;
  padding: 10px;
  margin: 0px;

}


.claims-modal .css-ypiqx9-MuiDialogContent-root {
  padding: 0px 10px;
  overflow: hidden;
}

.rule-builder .inputbox,
.rule-builder-select .css-wb57ya-MuiFormControl-root-MuiTextField-root {
  margin-left: 0px !important;
  width: 100% !important;
}

/* --------------------- RPA CSS starts here --------------------- */
.rpaCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 10px;
  width: 75%;
  background: #FFFFFF !important;
  border: 1px solid #D8D8D8 !important;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05) !important;
  border-radius: 15px !important;
  margin: 10px 125px 0px 0px !important;
}

.rpaCardHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 30px !important;
  width: 100% !important;
  height: 40px !important;
  background: #24476A !important;
  border-radius: 15px 15px 0px 0px !important;
}

.rpaCardHead h5 {
  font-size: 18px;
  margin-top: 6px;
}

.rpaContent {
  width: 100% !important;
  font-family: 'Futura PT' !important;
  padding: 10px 0px 10px 0px !important;
}

.rpa_select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 3px 15px 7px 10px;
  width: 100%;
  height: 30px;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
  border-radius: 9px;
  background: url('../../assets/icons/drop.svg') no-repeat right;
  background-position: right 15px top 53%;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.02em;
  color: #535353;
  text-align: left;
}

.rpa_select:focus {
  outline: none !important;
}

.batchInput {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 4px 10px 7px 10px;
  gap: 5px;
  width: 100%;
  height: 30px;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
  border-radius: 9px;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.02em;
  color: #535353;
}

.batchDisable {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 4px 10px 7px 10px;
  gap: 5px;
  width: 100%;
  height: 30px;
  background: #EFEFEF;
  border: 1px solid #D8D8D8;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
  border-radius: 9px;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.02em;
  color: #535353;
}

.batchDisable:focus {
  outline: none !important;
}

.batchInput:focus {
  outline: none !important;
}

.font12 {
  font-size: 12px;
}

.selDiv {
  display: flex;
}

.selImg {
  width: 25px;
  height: 30px;
  background-color: #24476A;
  border-radius: 6px;
  padding: 3px 2px 0px 3px;
  margin-right: 8px;
  cursor: pointer;
}

/* 03/05/2024 */

.withinday {
  max-width: 400px;
  width: 100%;
}

.custom_switch .MuiButtonBase-root  {
  padding: 5px!important;
}

.seltoggle {
  right: 0px;
  transform: translate(100% , 0px);
  top: 44%;
  font-size: 12px;
  color: #000;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #D8D8D8;
  width: 100%;
  max-width: 250px;
  cursor: default;
}

.seltoggle .upper-section {
 text-align: center;
  background-color: #24476A;
  border-top-left-radius: 4px;
  border-top-right-radius: 10px;
}

.seltoggle ul li {
  padding: 4px;
  border-bottom: 1px solid #D8D8D8;
}

.seltoggle ul li:last-child {
  border-bottom: none;
}


.seltoggle .arrow-left {
  position: absolute;
  top: 1px;
  left: -16px;
  height: 24px!important;

}

.seltoggle .arrow-right {
  position: absolute;
  top: 3px;
  right: 0px;
  padding-inline: 8px;
}

.seltoggle .arrow-right img {
  padding: 4px;
  cursor: pointer;
}

.seltoggle .arrow-right img:first-child {
  margin-right: 4px;
}

.seltoggle h6 {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 0px;
  padding: 4px;
}

.seltoggle ul  { 
  max-height: 200px;
  width: 100%;
  min-width: 176px;
  height: 100%;
  overflow: auto;
}
.seltoggle ul li.active {
  font-weight: 500;
  background-color: #00708C;
  font-size: 15px;
  color: #fff;
}
.label-wrap{
  min-height: 30px !important;
  height: auto !important;
}
.labelbox {
  background-color: #FFFFFF !important;
  text-align: left;
  width: 100% !important;
  margin-left: 0rem !important;
  padding: 3px 15px 0px 4px;
  height: 35px !important;
  border: 1px solid #D8D8D8 !important;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05) !important;
  border-radius: 9px !important;
}
.rpa-chips {
  border-radius: 9px;
  background-color: #5CB02C;
  padding: 5px 10px 5px 10px;
  color: white;
  font-size: 15px;
}

/* --------------------- RPA CSS ends here --------------------- */


@media screen and (max-width:1800px) {
  .class2 .col-lg-1 {
    flex: 0 0 auto;
    width: 10.633333%;
  }

  .class2 {
    margin-left: 3rem;
  }

  .class1 {
    margin-left: 3rem;
  }

  .class1 .col-lg-1 {
    width: 9.5% !important;
  }

  .class1 .col-lg-2 {
    width: 17%;
  }

  /* .dropd2 {
    width: 83% !important;
    margin-left: 3.3rem !important;
  }

  .dropd {
    width: 83.3% !important;
    margin-left: 3.8rem !important;
  } */
  .mr-r {
    margin-right: 0rem;
    margin-left: 4rem;
  }

  .inputbox2 {
    width: 83% !important;
    margin-left: 3.3rem !important;
  }

  .inputbox {
    width: 83% !important;
    margin-left: 3.3rem !important;
  }

  .mrtop-70 {
    margin-top: 70px;
  }

  .rule-box {
    max-height: 135px;
  }

  /* .main-content {
    height: 86vh;
  } */

  .cdflex span {
    font-size: 16px !important;
    margin-right: 0.5rem;
  }

  .days-select {
    width: 53%;
  }

  .gap-right {
    margin-right: 1rem !important;
  }

  .setgap {
    /*06/11/2023*/
    width: 64% !important;
  }

  .gapl {
    margin-left: 1rem;
  }

  .for-procedure {
    font-size: 20px !important;
  }

}

@media screen and (max-width:1536px) {
  .class2 {
    margin-left: 1rem;
  }

  .class2 .col-lg-2 {
    flex: 0 0 auto;
    width: 17.666667%;
  }

  .class3 .col-lg-1 {
    width: 16.5% !important;
  }

  .class1 .col-lg-1 {
    width: 11.5% !important;
  }

  .class1 {
    margin-left: 2rem;
  }

  .setgap {
    width: 68% !important;
  }

  .mr-r {
    margin-right: 1rem;
  }

  .gap-right {
    margin-right: 0rem !important;
  }

  .days-select {
    width: 49%;
  }

  /* .dropd {
      width: 84.5% !important;
      margin-right: 0rem !important;
  }

  .dropd2 {
    width: 83% !important;
    margin-left: 2.3rem !important;
  } */

  .inputbox {
    width: 83% !important;
    margin-right: 1rem !important;
  }

  .inputbox2 {
    width: 83% !important;
    margin-left: 2.3rem !important;
  }

  .cdflex span {
    font-size: 13px !important;
    margin-right: 0.5rem;
  }

  .cdflex select {
    width: 32%;
  }

  .mrtop-70 {
    margin-top: 20px;
  }

  /*06/11/2023*/

  .for-procedure {
    font-size: 18px !important;
  }

  /*06/11/2023 */
}




/* 24/04/204 start media query for 1400px */

@media screen and (max-width:1400px) and (max-height:850px){

  .playerlist-datatable .MuiCard-root:nth-child(2) {
    height: 190px!important;
  }
  .playerlist-datatable table tbody {
    height: 140px!important;
  }
}

@media screen and (max-width:1280px) {
  .rule-footer {
    bottom: -48px;
    width: 88.2%;
  }

  .rulestyle {
    width: 257px;
    height: calc(100vh - 284px);
  }

  .custom-table {
    margin-bottom: 5rem;
  }
}

@media screen and (max-width:1200px) {
  .selectStyle {
    margin-bottom: 0.5rem;
    width: 100%;
  }

  .tleft {
    margin-right: 14rem;
  }

  .MuiCardContent-root:last-child {
    position: relative;
    padding: 15px 15px 42px 15px;
  }

  .numeric-digit {
    margin-left: -9px;
  }

  .the {
    text-align: left;
  }

  .rulestyle {
    height: calc(100vh - 276px);
  }

  .reason {
    width: 100%;
  }

  .pos {
    width: 100%;
  }
}

/* iPad Air */
@media only screen and (device-width: 1368px) and (device-height: 912px) and (orientation : landscape) {

  .rulestyle {
    width: 257px;
    height: calc(100vh - 285px);
  }
}

@media only screen and (device-width: 1280px) and (device-height: 800px) and (orientation : landscape) {

  .rulestyle {
    width: 257px;
    height: calc(100vh - 152px);
  }

  .right-sidebar {
    height: 106vh;
  }

  .card-style {
    height: calc(100vh - 52px);
    padding-bottom: 15px;
  }
}

@media only screen and (device-width: 1180px) and (device-height: 820px) and (orientation : landscape) {
  .css-feqhe6 {
    width: 66% !important;
  }

  .rulestyle {
    width: 257px;
    height: calc(100vh - 168px);
  }

  .right-sidebar {
    height: 104vh;
  }

  .card-style {
    height: calc(100vh - 67px);
  }
}

@media only screen and (device-width: 1024px) and (device-height: 768px) and (orientation : landscape) {
  .css-feqhe6 {
    width: 100% !important;
  }

  .rulestyle {
    width: 257px;
    height: calc(100vh - 44px);
  }

  .right-sidebar {
    height: 120vh;
  }

  .card-style {
    height: calc(100vh - 36px);
  }

  .css-wb57ya-MuiFormControl-root-MuiTextField-root {
    width: 100% !important;
  }

}

@media screen and (max-width:1024px) {
  .rule-footer {
    bottom: -81px;
  }

  .rule-footer {
    width: 86.8%;
  }

  .row-change .row {
    flex-direction: column;
  }

  .row-change .col-lg-4,
  .col-lg-2,
  .col-lg-8 {
    width: 100%;
  }

  .custom-scroll {
    height: 100%;
    overflow: scroll;
  }

  .col-xxl-9 {
    width: 75% !important;
  }

  .col-xxl-3 {
    width: 25% !important;
  }
}

@media screen and (max-width:912px) {
  .custom-size {
    height: 1162px;
  }

  .rule-footer {
    width: 85.7%;
  }

  .right-sidebar {
    height: 90vh;
  }

  .rulestyle {
    height: 80vh;
  }
}

@media screen and (max-width:820px) {
  .css-wb57ya-MuiFormControl-root-MuiTextField-root {
    width: 100% !important;
  }

  .css-feqhe6 {
    width: 100% !important;
  }

  .custom-table {
    margin-bottom: 6rem;
  }

  .custom-size {
    height: 1014px;
  }

  .rule-footer {
    position: absolute;
    bottom: 63px;
    width: 84.99%;
  }

  .right-sidebar {
    height: 88vh;
  }

  .rulestyle {
    height: 77vh;
  }

  .card-style {
    height: 97%;
  }
}

@media screen and (max-width:768px) {
  .custom-size {
    height: 751px;
  }

  .custom-table {
    margin-bottom: 6rem;
  }

  .rule-footer {
    position: absolute;
    bottom: 166px;
    width: 84.4%;
  }

  .ml-30 {
    margin-left: 9px;
  }

  .the {
    text-align: left;
  }

  .MuiCardContent-root:last-child {
    position: relative;
    padding: 15px 15px 42px 15px;
  }

  .selectStyle {
    margin-bottom: 0.5rem;
    width: 100%;
  }

  .numeric-digit {
    margin-left: -9px;
  }

  .chips {
    width: 100%;
  }

  .tleft {
    margin-right: 15rem;
  }

  .reason {
    width: 100%;
  }

  .pos {
    width: 100%;
  }

  .rulestyle {
    height: 73vh;
  }

  .right-sidebar {
    height: 86vh;
  }

  .card-style {
    height: 94%;
  }
}

@media screen and (min-width:320px) and (max-width:767px) {
  .main-content {
    display: flex;
    flex-direction: column;
    margin-left: 74px;
  }

  .content-area {
    width: auto;
  }

  .right-sidebar {
    width: 100%;
    max-width: 97%;
    margin-right: 0rem;
    margin-top: 1rem;
  }

  .card-style {
    height: auto;
  }

  .custom-scroll {
    height: 500px;
    overflow: scroll;
  }

  .the {
    text-align: center;
  }

  /* .dropd {
    width: 90% !important;
  } */

  .reason {
    width: 100%;
  }

  .pos {
    width: 100%;
  }
}

/* 27/03/2024 */
.bgWhite {
  background-color: white !important;
}