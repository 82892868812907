/* input {
    position: relative;
    width: 150px; height: 20px;
    color: white;
}

input:before {
    position: absolute;
    top: 3px; left: 3px;
    content: attr(data-date);
    display: inline-block;
    color: black;
}

input::-webkit-datetime-edit, input::-webkit-inner-spin-button, input::-webkit-clear-button {
    display: none;
}

input::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    right: 0;
    color: black;
    opacity: 1;
} */
.font-face-futura-footer {
    font-family: "Futura PT";
    font-weight: 600;
}

.font-face-futura-placeholder {
    font-family: "Futura PT";
    font-weight: 400;
    color: #535353;
    font-size: 15px;
}

.footer-left-area {
    display: flex;
    justify-content: flex-start;
    width: calc(50% - 13px);
    margin-left: 13px;
}

.t-left {
    text-align: left;
    line-height: 3.3rem;
}

.denDivFrom {
    display: flex;
    align-items: center;
    margin-right: -30px;
}
.denDivTo {
    display: flex;
    align-items: center;
    margin-right: -10px;
}

.scrubTxt {
    font-size: 12px;
    width: 113px;
    margin-right: -10px;
}
.denFromTxt {
    font-size: 12px;
    width: 167px;
    margin-right: -40px;
}
.denToTxt {
    font-size: 12px;
    width: 135px;
    margin-right: -35px;
}

.date-picker {
    color: #535353;
    height: 30px;
    width: 180px;
    background: #F5F5F5;
    border-radius: 9px;
    border: none;
    padding: 0px 8px;
    font-size: 16px;
    font-family: 'Futura PT';
}

.claimDiv {
    display: flex;
    align-items: baseline;
    margin-left: 50px;
}

.none-selector {
    display: none;
}

.show-selector {
    display: block;
}

.filter-icon {
    display: -webkit-box;
}
.filter-icon p {
    font-family: 'Futura PT';
    font-size: 10px;
    color: #FFFFFF;
    cursor: pointer;
    margin: 25px 0px 0px -44px;
}

.sticky-selection {
    position: absolute;
    bottom: 55px;
    overflow: hidden;
    height: 108px;
    width: 200px;
    border-radius: 9px;
    padding: 4px 10px 0px 10px;
    cursor: pointer;
    background: #FAFAFA;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
}

.sticky-selection2 {
    position: absolute;
    bottom: 70px;
    /* overflow: hidden; */
    height: 336px;
    width: 418px;
    border-radius: 5px;
    padding: 6px 10px 0px 10px;
    cursor: pointer;
    background: #00708C;
    box-shadow: 2px 5px 4px rgba(0, 0, 0, 0.17);
    z-index: 1;
}

.filter-row {
    line-height: 2rem;
    font-family: 'Futura PT';
}

.filter-txt {
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    letter-spacing: -0.02em;
    color: rgba(255, 255, 255, 0.5);

}

.sticky-sel {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 3px 15px 3px 10px;
    line-height: 13px;
    font-size: 12px;
    gap: 5px;
    width: 397.38px;
    height: 21px;
    background-color: #FFFFFF;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
    background-image: url('../../assets/icons/drop.svg');
    background-repeat: no-repeat;
    background-position: right 15px top 53%
}

.sticky-sel:focus {
    outline: none;
}

.fil-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    width: 397.38px;
    height: 260px;
    background: #FFFFFF;
    border-radius: 9px;
}

.fil-box2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    width: 397.38px;
    height: 240px;
    background: #FFFFFF;
    border-radius: 9px;
}

.goBack {
    font-family: 'Futura PT';
    font-size: 16px;
    font-weight: 450;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
}
.bottom {
    border-bottom: 1px solid #D8D8D8;
    width: 370px;
    margin: -4px -8px;
}

.fil-input {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 7px 15px 7px 10px;
    width: 380px;
    height: 30px;
    background-color: #FFFFFF;
    background-image: url('../../assets/icons/drop.svg');
    background-repeat: no-repeat;
    background-position: right 15px top 53%;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
}

.fil-input:focus {
    outline: none;
}

.fil-drop {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 10px;
    width: 385px;
    height: 193px;
    overflow-y: auto;
    margin-top: -5px;
}
.fil-drop2 {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 10px;
    width: 385px;
    /* height: 193px; */
    height: 220px;
    overflow-y: auto;
    margin-top: -5px;
    margin-bottom: 10px;
}

.fil-opt {
    display: flex;
    flex-direction: row;
    padding: 0px 10px;
    gap: 10px;
    line-height: 2rem;
}

.fil-txt {
    margin-left: 15px;
}
.place-txt {
    margin: 100px 100px;
    font-family: 'Futura PT';
    font-size: 16px;
}

.option {
    border-bottom: 1px solid lightgrey;
}

.sticky-select {
    position: absolute;
    bottom: 55px;
    overflow: hidden;
    height: 142px;
    width: 260px;
    border-radius: 10px;
    padding: 6px 10px 0px 10px;
    margin: 0px 0px 0px 346px;
}

.drop {
    width: 260px;
    height: 30px;
    margin: 15px 0px 0px 20px;
    padding: 4px 15px 5px 10px;
    gap: 5px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
}

.claims {
    margin: 15px 0px 0px 20px;
}

.claimsDrop {
    margin: 13px 0px 0px 10px;
    height: 30px;
    width: 260px;
    padding: 4px 15px 5px 10px;
    gap: 5px;
    background-color: #FFFFFF !important;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
    background: url('../../assets/icons/drop.svg') no-repeat right;
    background-position: right 15px top 53%
}

.claimInput {
    margin: -3px 28px 0px -3px;
    border: none;
    width: 225px;
    color: transparent;
    cursor: pointer;
}

.claimInput:focus {
    outline: none;
}

.searchInput {
    margin-top: 13px;
    margin-left: 13px;
    float: left;
    height: 30px;
    width: 270px;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
}

.searchInput:focus {
    outline: none;
}

.foot-calendar {
    display: flex;
    align-items: center;
    float: right;
    margin: 10px 40px 0px 0px;
}

.react-datepicker-wrapper {
    width: 100% !important;
}
.claimDiv{
    position: relative;
}

.claimDiv .sticky-select{
    right: 0;
    bottom: 45px;
}

