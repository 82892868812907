.one-line-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.font-face-futura {
    font-family: "Futura PT";
    font-weight: 450;
}
.font-face-futura-rule {
    font-family: "Futura PT";
    font-weight: 400;
    color: #535353;
}
.font-face-futura-book {
    font-family: "Futura Book PT";
}
.tableroundedCorner {
    background-color: #24476A;
    border-radius: 9px;
    font-size: 14px;
}
.font-face-roboto{
    font-family: 'Roboto';
    font-weight: 400;
}
.iconBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    padding: 5px 0px 5px 10px;
    width: 600px;
    height: 45px;
    /* left: calc(50% - 600px/2 - 570px); */
    left: calc(50% - 600px/2 - 620px);
    top: 83px;
}
.iconText {
    font-family: 'Futura Book PT';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    align-items: center;
    letter-spacing: -0.06em;
    color: #0E0E2C;
}
.editIcon{
    /* width: 17.9px;
    height: 17.9px; */
    color: #5CB02C;
    margin: 0px 6px 6px 0px;
    /* left: calc(50% - 17.9px/2 + 0.45px);
    top: calc(50% - 17.9px/2 + 0.55px); */
}
.copyIcon{
    width: 19px;
    height: 21px;
    margin: 0px 6px 6px 0px;
    /* left: calc(50% - 19px/2 + 2px);
    top: calc(50% - 21px/2 + 1px); */
}
.powerIcon{
    width: 18px;
    height: 20px;
    margin: 0px 6px 6px 0px;
    /* left: calc(50% - 18px/2 + 0.5px);
    top: calc(50% - 20px/2 + 0.5px); */
}
.deleteIcon{
    /* width: 14.06px;
    height: 17.19px; */
    color: #5CB02C;
    margin: 0px 6px 6px 0px;
    /* left: calc(50% - 7.03px/2);
    top: calc(50% - 17.19px/2 - 1.56px); */
}

.trigDiv{
    display: flex;
    padding: 15px;
    gap: 10px;
    width: 97%;
    height: 60px;
    background: #24476A;
    border-radius: 9px;
    margin: 0px 0px 0px 30px;
}
.trigSelect{
    display: flex;
    float: right; 
    border-radius: 9px; 
    font-size: 16px; 
    height: 30px; 
    width: 110px!important; 
    padding: 2px 0px 1px 6px;
    margin: -45px 50px 20px 0px;
    /* background: #FFFFFF;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgb(0 0 0 / 5%);
    line-height: 21px;
    letter-spacing: -0.02em;
    color: #535353;
    font-family: 'Futura PT';
    background: url('../../assets/icons/drop.svg') no-repeat right;
    background-position: right 8px top 53%; */
}
.trigSelect:focus {
    outline: none;
}

.detailCard {
    border: 1px solid #D8D8D8;
    background: #FAFAFA !important;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05) !important;
    width: 95%;
    text-align: left;
    font-size: 18px;
    border-radius: 9px !important;
}

.rule-footer2 {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 80px;
    background-color: #FAFAFA;
    color: white;
    text-align: center;
    border-radius: 9px;
    display: flex;
}
.foot-flex-exp {
    display: flex;
    justify-content: flex-start;
    padding: 15px;
    align-items: center;
    background-color: #FAFAFA;
    border-radius: 9px;
    width: 92%;
}
.export {
    color: #535353;
    font-size: 15px;
    font-family: 'Futura PT';
}
.foot-flex2 {
    display: flex;
    justify-content: flex-end;
    padding: 15px;
    align-items: center;
    background-color: #FAFAFA;
    border-radius: 9px;
    height: 70px;
    margin: 6px 10px 10px 0px;
}
.foot-right2 {
    width: 100%;
    max-width: 101px;
    text-align: left;
}
.font-futura-total {
    font-family: 'Futura PT';
    font-size: 15px;
    color: #0E0E2C;
    letter-spacing: -0.02em;
}
.font-futura-status {
    font-family: 'Futura PT';
    font-size: 12px;
    color: #9B9B9B;
    letter-spacing: -0.02em;
}

.innerdata{
    padding: 18px;
    line-height: 1.7rem;
}
.table-row:nth-child(even) .innerdata{
    background-color: #cef8b6;
}
table th{
    border-bottom: 0px!important;
    white-space: nowrap;
}
.table-row:nth-child(odd) .innerdata{
    background-color: #f2f2f2;
}
.table-row td{
    border-bottom: 0px!important;
    border-top: 0px!important;
    white-space: nowrap;
}
.css-1pqm26d-MuiAvatar-img{
    width: auto!important;
}
.custom-table th:nth-child(1){
border-radius: 9px 0px 0px 9px!important;
}

.custom-table th:last-child{
    border-radius: 0px 9px 9px 0px!important;
}
.manager{
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 9px;
    /* margin-left: 30px; */
    position: relative;
}
.manager .innerdata {
    padding: 18px;
    height: 48px;
}
.manageTable {
    margin-bottom: 1.8rem;
}
.manageTable thead {
    width: 90%; 
    height: 50px;
    background-color: #24476A;
    border-radius: 9px;
}
.manageTable tbody {
    display: block;
    max-height: 55vh;
    height: 55vh;
    overflow-y: scroll;
    margin-bottom: 3rem;
    margin-top: 0.75rem;
}
.manageTable thead, .manageTable tbody tr{
  display: table;
  table-layout: fixed;
  width: 100%;
}
.noRecord {
  font-size: 16px;
  color: #954437;
}
.tableManager{
    width: calc(100% - 140px) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 9px;
    margin-left: 95px;
}
.tableManager .innerdata {
    padding: 18px;
    height: 48px;
}
.managerTable thead {
    width: 90%; 
    height: 50px;
    background-color: #24476A;
    border-radius: 9px;
}
.managerTable tbody {
    display: block;
    max-height: 65vh;
    /* height: auto; */
    height: 10vh;
    overflow-y: scroll;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
}
.managerTable thead, .managerTable tbody tr{
  display: table;
  table-layout: fixed;
  width: 100%;
}

.copyDrop{
    padding: 7px 15px 7px 10px;
    gap: 5px;
    width: 500px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.02em;
    color: #535353;
}
.tabManager{
    width: 97%;
    margin: 30px 0px 0px 30px;
}
.managerTable :is(thead th:nth-child(1) , tbody tr td:nth-child(1)) {
    width: 14% !important;
}
.managerTable :is(thead th:nth-child(2) , tbody tr td:nth-child(2)){
    width: 10% !important;
}
.managerTable :is(thead th:nth-child(3) , tbody tr td:nth-child(3)){
    width: 8% !important;
}
.managerTable :is(thead th:nth-child(4) , tbody tr td:nth-child(4)){
    width: 8% !important;
}
.managerTable :is(thead th:nth-child(5) , tbody tr td:nth-child(5)){
    width: 8% !important;
    padding-right: 0.6rem;
}
.managerTable :is(thead th:nth-child(6) , tbody tr td:nth-child(6)){
    width: 14% !important;
    padding-right: 1.5rem;
}
.managerTable :is(thead th:nth-child(7) , tbody tr td:nth-child(7)){
    width: 14% !important;
    padding-right: 1.5rem;
}
.managerTable :is(thead th:nth-child(8) , tbody tr td:nth-child(8)){
    width: 14% !important;
    padding-right: 1.5rem;
}
.managerTable :is(thead th:nth-child(9) , tbody tr td:nth-child(9)){
    width: 8% !important;
    padding-right: 1.5rem;
}
.managerTable :is(thead th:nth-child(10) , tbody tr td:nth-child(10)){
    width: 8% !important;
    padding-right: 1.5rem;
}
.managerTable :is(thead th:last-child , tbody tr td:last-child){
    width: 8% !important;
    padding-right: 1.5rem;
}


@media screen and (min-width:320px) and (max-width:767px){
    .innerdata{
        display: flex;
    }
}
@media screen and (max-width:1800px){
    .manageTable tbody {
        max-height: 51vh;
    }
    .iconBox{
        width: 550px;
        left: calc(50% - 600px/2 - 490px);
    }
    .trigDiv{
        width: 92%;
    }
    .trigSelect{
        margin: -45px 60px 20px 0px;
    }
    .tableManager{
        width: calc(100% - 137px) !important;
    }
    .rule-footer2 {
        bottom: 0px;
        width: 100%;
    }
    .foot-flex-exp {
        width: 91%;
    }
}
@media screen and (max-width:1536px){
    .foot-flex-exp {
        width: 90%;
    }
    .manageTable tbody {
        max-height: 46vh;
    }
    .iconBox{
        width: 530px;
        left: calc(50% - 600px/2 - 380px);
    }
    .trigDiv{
        width: 91.25%;
    }
    .trigSelect{
        margin: -45px 50px 20px 0px;
    }
    .tableManager{
        width: calc(100% - 132px) !important;
    }
    .rule-footer2 {
        bottom: 0px;
        width: 100%;
    }
}

/*06/11/2023*/
.export-btn{
    display: flex;
    flex-direction: column;
}
.export-btn span{
    margin-top: 5px;
}
.MuiIconButton-root:hover{
    background-color: unset !important;
}   
