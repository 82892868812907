.text-lead {
  font-weight: 500 !important;
  font-size: 16px;
}

/* .trizetto-table-cell {
  background: transparent;
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  height: 40px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.02em;
  border-bottom: 1px solid #d8d8d8;
  border-radius: 0px;
  padding: 10px;
  cursor: pointer;
} */

.trizetto-table-cell-active {
  background: #5cb02c;
  color: white;
}

.table-footer-text {
  font-size: 14px;
  font-weight: 400;
}

.state-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* change the select option background color  */

.playerlist-datatable .MuiCard-root:nth-child(2) {
  height: 350px !important;
}

.playerlist-datatable table tbody {
  height: 300px;
}

.main-cont {
  /* margin-left: 20px; */
  display: flex;
  /* margin-top: 70px; */
}

.cont-area {
  /* width: calc(100% - 80px); */
  /* margin-top: 1rem; */
}

.p-main-cont {
  /* width: 435px; */
  display: flex;
  /* margin-top: 60px; */
}

/* .p-cont-area {
  width: 100%;
  margin-right: 155px;
  margin-left: -95px;
  margin-top: 1rem;
} */

.payDiv {
  background: #FAFAFA;
  border: 1px solid #b3b1b18e;
  box-sizing: border-box;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.25);
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.botDivider {
  border-bottom: 1px solid #DDDDDD;
  margin: 4px;
}

.payer-btn {
  width: 100%;
  height: 30px;
  border-radius: 9px !important;
  background-color: #00708C !important;
}

.never-btn {
  width: 160px;
  height: 30px;
  background-color: #954437 !important;
  border-radius: 9px !important;
}

.link-btn {
  width: 160px;
  height: 30px;
  background-color: #00708C !important;
  border-radius: 9px !important;
}

.linkedsection-layout {
  margin-top: 75px;
  padding: 10px 25px;
}

.linkedsection-grid {
  display: grid;
  grid-template-columns: auto 366px;
  column-gap: 10px;
}

.payerdetail-form {
  /* padding-bottom: 40px; */
  margin-bottom: 20px;
}

.payerdetail-form input[type=text],
.payerdetail-form .help-payer,
.payerdetail-form select {
  margin-bottom: 12px;
}

.payerdetail-form .help-payer input[type=text] {
  margin-bottom: 0px;
}

.payerdetail-form select {
  width: 100%;
  font-size: 14px;
}

.payerdetail-form label {
  word-spacing: -2px;
  font-size: 14px;
  font-weight: 600;
}

.payerdetail-check {
  padding: 10px 0px 5px;
  border-top: 1px solid #d8d8d8;
}

.payerdetail-check input[type=text] {
  margin-bottom: 20px;
  height: 45px;
}

.linkedsection-form .payerdetail-check .textInputLabel {
  font-weight: 600;
  font-size: 14px;
}

/* table  */

.denial-table thead {
  background: #24476A;
  border-radius: 9px;
  /* height: 70px; */
  color: #fff;
  position: relative;
}

.denial-table tbody {
  display: block;
  overflow-y: scroll;
  padding: 10px 0px 80px 10px;
  height: calc(100vh - 350px);
}

.denial-table thead,
.denial-table tbody tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.denial-style table {
  font-size: 14px;
  width: 100%;
  table-layout: fixed;
}

.denial-style table .checkbox-container {
  margin: 0px;
}

.denial-style .table-row td:nth-child(1) .innerdata {
  padding: 0px !important;
  overflow: visible;
  position: relative;
}

.denial-style .table-row td:nth-child(1) .innerdata .checkbox-container {
  margin: 0px !important;
}

.denial-style .table-row td .innerdata,
.denial-style .table-row th {
  line-height: normal;
}

.denial-style table .deal-content {
  white-space: normal;
  line-height: normal;
}

.denial-style .foot-flex {
  padding: 8px 35px 8px 10px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.denial-style .rule-footer .fontw {
  font-size: 14px;
}

.denial-style table .innerdata {
  height: auto;
}

table .table-row.active-tablerow {
  outline-style: solid;
  outline-color: #5CB02C;
  outline-width: thin;
  outline-offset: -1px;
  box-shadow: 6px 6px 8px rgba(92, 176, 44, .25);
  border-radius: 9px !important;
  overflow: hidden;
}

.denial-style table .table-row .innerdata {
  font-size: 12px;
  background-color: transparent;
  padding: 6px 4px;
}

.denial-style table .table-row {
  margin-bottom: 10px;
  border-radius: 9px;
}

.denial-style table .table-row:nth-child(even) {
  background-color: #CEF8B6;
}

.denial-style table .table-row:nth-child(odd) {
  background-color: #F2F2F2;
}

.denial-style table .checkbox-container {
  width: 100%;
  height: 100%;
}

.denial-style table .checkmark {
  height: 20px;
  width: 20px;
}

.denial-style table tbody .checkmark {
  left: 0%;
  top: 50%;
  transform: translate(0px, -50%);
}

.denial-style table .checkbox-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
}

.denial-style table thead .checkmark {
  left: 0px;
  top: 50%;
  transform: translate(0px, -50%);
}

/* Nandan P 11/02/2025 */
.insurancecom-table table tbody :is(td:nth-child(2), td:nth-child(3)),
.insurancecom-table table thead :is(th:nth-child(2), th:nth-child(3)) {
  width: 200px;

}

.insurancecom-table table tbody td {
  padding-left: 20px !important;
}

.insurancecom-table table tbody {
  height: calc(100vh - 590px);
}

.savebtn-modal ul {
  padding: 0px !important;
  border-radius: 0px;
}

.savebtn-modal .css-6hp17o-MuiList-root-MuiMenu-list li {
  font-size: 11px;
  padding: 10px !important;
  font-weight: 700;
  font-family: 'Futura PT';
}

.css-iajp3t-MuiButtonGroup-root {
  anchor-name: --my-anchor;
}

.savebtn-modal .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  position: absolute;
  position-anchor: --my-anchor;
  margin-left: 8px;
  margin-top: 4px;
}

.manager-table tbody {
  height: calc(100vh - 260px);
}

.claim-table tbody {
  height: calc(100vh - 240px);
}

@media only screen and (max-height:970px) {

  .payerdetail-form input[type=text],
  .payerdetail-form .help-payer,
  .payerdetail-form select,
  .payerdetail-check input[type=text] {
    margin-bottom: 4px;
  }

  .payerdetail-form {
    margin-bottom: 4px;
  }

  .payerdetail-check {
    padding: 8px 0px;
  }
}